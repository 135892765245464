<section id="in-the-news" class="uk-section uk-dark">
    <div class="uk-container">
        <div class="home-section-title">
            <h2>News & Blog</h2>
            <p>Latest updates and articles from Thirium.</p>
            <br>
        </div>
        
<!-- Featured News Articles -->
<div class="news-articles uk-grid-match" uk-grid>
    <div *ngFor="let article of featuredArticles" class="uk-width-1-3@m">
        <div class="elementor-post__card featured-article">
            <span class="featured-label">Featured</span>
            <a class="elementor-post__thumbnail__link" [href]="article.link">
                <div class="elementor-post__thumbnail">
                    <img [src]="article.imgSrc" alt="News Image">
                </div>
            </a>
            <div class="elementor-post__text">
                <h3 class="elementor-post__title">
                    <a [href]="article.link">{{article.title}}</a>
                </h3>
                <div class="elementor-post__excerpt">
                    <p>{{article.excerpt}}</p>
                </div>
            </div>
            <div class="elementor-post__meta-data">
                <span class="elementor-post-date">{{article.date}}</span>
            </div>
        </div>
    </div>
</div>



        <!-- Other News Articles -->
        <div class="news-articles uk-grid-match" uk-grid>
            <div *ngFor="let article of displayedOtherArticles" class="uk-width-1-3@m">
                <div class="elementor-post__card">
                    <a class="elementor-post__thumbnail__link" [href]="article.link">
                        <div class="elementor-post__thumbnail">
                            <img [src]="article.imgSrc" alt="News Image">
                        </div>
                    </a>
                    <div class="elementor-post__text">
                        <h3 class="elementor-post__title">
                            <a [href]="article.link">{{article.title}}</a>
                        </h3>
                        <div class="elementor-post__excerpt">
                            <p>{{article.excerpt}}</p>
                        </div>
                    </div>
                    <div class="elementor-post__meta-data">
                        <span class="elementor-post-date">{{article.date}}</span>
                    </div>
                </div>
            </div>
        </div>
        
        <button *ngIf="displayedOtherArticles.length < otherArticles.length" (click)="showMoreArticles()" class="uk-button uk-button-default">Load More</button>
    </div>
</section>
