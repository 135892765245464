<!-- Start Initiative with Hood Tech Area -->
<section class="collaboration-details-area uk-section uk-dark">
    <div class="uk-container">
        <article class="uk-article collaboration-details">
            <div class="uk-grid uk-flex">
          
                <div class="inner uk-width-expand">
                    <div class="services-details-desc">
                        <h3>Initiative with Hood Tech</h3>
                        <p>At Thirium, we constantly seek opportunities to push the boundaries of innovation. Recognizing the unmatched lens quality of Hood Tech's EO-900 series camera, especially at high altitudes, we approached them with a pioneering vision. With their endorsement, we embarked on the journey to design and develop the world's first retraction system for the EO-900 series camera. This unique system not only enhances the camera's capabilities but also makes it adaptable to fixed-wing platforms, eliminating the challenges of belly landings and catch systems. This initiative stands as a testament to Thirium's commitment to driving technological advancements and expanding the horizons of aerial imaging.</p>

                        <div class="services-image-slides owl-carousel owl-theme">
                            <div class="item">
                                <img src="assets/img/payloads/retractionsystem.png" alt="Retraction System Image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </article>
    </div>
</section>
<!-- End Initiative with Hood Tech Area -->
