<!-- Start Red Line Area -->
<section id="about" class="uk-about uk-dark about-area uk-section">
    <div class="uk-container">
        <div class="uk-grid uk-child-width-1-2@m uk-child-width-1-1@s" uk-grid>
            <div class="item">
                <div class="about-content">
                    <div class="uk-section-title section-title">
                        <span>UAS </span>
                        <h2>Red Line Stream</h2>
                        <div class="bar"></div>
                    </div>

                    <div class="about-text">
                        <div class="icon">
                            <i class="flaticon-quality"></i>
                        </div>
                        <div>
                            <h2>Introducing Red Line</h2>
                            <p>Our cutting-edge streaming solution with dual AES 256 encryption tailored specifically for the UAS industry.</p>
                            <h3>Key Features:</h3>
                            <ol>
                              <li>
                                <strong>Superior Video Quality:</strong> Enjoy high-quality video streaming that delivers exceptional clarity and consistency, even across long distances of up to 4,000 miles. Red Line ensures that every detail is captured with precision.
                              </li>
                              <li>
                                <strong>Robust AES256 Security:</strong> We prioritize the security of your UAS video communication. With dual AES 256 encryption, Red Line provides robust data protection, giving you peace of mind and safeguarding your sensitive information.
                              </li>
                              <li>
                                <strong>Enhanced Situational Insight:</strong> Red Line empowers UAS operations with increased situational awareness, enabling better decision-making capabilities. Stay informed and make critical judgments based on real-time information.
                              </li>
                              <li>
                                <strong>Instant Team Chat:</strong> Facilitate seamless collaboration within your team using Red Line's real-time chat feature. Enhance communication and streamline decision-making processes, ensuring efficient coordination during UAS operations.
                              </li>
                              <li>
                                <strong>Multi-Stream Capability:</strong> Manage multiple live streams simultaneously with Red Line's multi-stream capability. Cover a wide range of UAS operational scenarios, allowing comprehensive monitoring and control.
                              </li>
                            </ol>
                            <p>Red Line revolutionizes UAS video streaming, providing industry-leading features and functionality tailored to meet the unique needs of the UAS industry. Experience the future of UAS streaming with Red Line.</p>
                          </div>
                          

                        <div class="signature">
                            <!-- <img src="assets/img/signature.png" alt="signature"> -->
                        </div>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="about-img uk-position-relative">
                    <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1" uk-slider>
                        <ul class="uk-slider-items uk-child-width-1-1">
                            <li>
                                <img src="assets/img/Red Line/1.png" alt="image" style="max-width: 100%;">
                            </li>
                            <li>
                                <img src="assets/img/Red Line/2.png" alt="image" style="max-width: 100%;">
                            </li>
                            <li>
                                <img src="assets/img/Red Line/3.png" alt="image" style="max-width: 100%;">
                            </li>
                            <li>
                                <img src="assets/img/Red Line/4.png" alt="image" style="max-width: 100%;">
                            </li>
                            <li>
                                <img src="assets/img/Red Line/5.png" alt="image" style="max-width: 100%;">
                            </li>
                            <li>
                                <img src="assets/img/Red Line/6.png" alt="image" style="max-width: 100%;">
                            </li>
                            <li>
                                <img src="assets/img/Red Line/7.png" alt="image" style="max-width: 100%;">
                            </li>
                            <li>
                                <img src="assets/img/Red Line/8.png" alt="image" style="max-width: 100%;">
                            </li>
                        </ul>
                        <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
                        <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slider-item="next"></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About Area -->


 <!-- Start UAS Track Pro Area -->
 <section id="about" class="uk-about uk-dark about-area uk-section" style="background-color: white;">
    <div class="uk-container">
        <div class="uk-grid uk-child-width-1-2@m uk-child-width-1-1@s" uk-grid>
            <div class="item">
                <div class="about-img uk-position-relative">
                    <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1" uk-slider>
                        <ul class="uk-slider-items uk-child-width-1-1">
                            <li>
                                <img src="assets/img/UAS Track Pro/1.png" alt="image" style="max-width: 100%;">
                            </li>
                            <li>
                                <img src="assets/img/UAS Track Pro/2.png" alt="image" style="max-width: 100%;">
                            </li>
                            <li>
                                <img src="assets/img/UAS Track Pro/3.png" alt="image" style="max-width: 100%;">
                            </li>
                            <li>
                                <img src="assets/img/UAS Track Pro/4.png" alt="image" style="max-width: 100%;">
                            </li>
                            <li>
                                <img src="assets/img/UAS Track Pro/5.png" alt="image" style="max-width: 100%;">
                            </li>
                            <li>
                                <img src="assets/img/UAS Track Pro/6.png" alt="image" style="max-width: 100%;">
                            </li>
                            <li>
                                <img src="assets/img/UAS Track Pro/7.png" alt="image" style="max-width: 100%;">
                            </li>
                            <li>
                                <img src="assets/img/UAS Track Pro/8.png" alt="image" style="max-width: 100%;">
                            </li>
                            <li>
                                <img src="assets/img/UAS Track Pro/9.png" alt="image" style="max-width: 100%;">
                            </li>
                            <li>
                                <img src="assets/img/UAS Track Pro/10.png" alt="image" style="max-width: 100%;">
                            </li>
                        </ul>
                        <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
                        <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slider-item="next"></a>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="about-content">
                    <div class="uk-section-title section-title">
                        <span style="color: #333;">UAS </span>
                        <h2 style="color: #333;">Track Pro</h2>
                        <div class="bar"></div>
                    </div>
            
                    <div class="about-text" style="color: #333;">
                        <div class="icon">
                            <i class="flaticon-quality"></i>
                        </div>
                        <div>
                            <h2 style="color: #333;">Introducing UAS Track Pro</h2>
                            <p style="color: #333;">Our advanced UAS tracking solution designed to enhance operational efficiency and safety.</p>
                            <h3 style="color: #333;">Key Features:</h3>
                            <ol>
                                <li>
                                    <strong>AI-Powered Custom Reports:</strong> UAS TrackPro's integrated AI chat system generates custom reports based on user inputs and available data. Download reports in Excel format for effective analysis and data management.
                                </li>
                                <li>
                                    <strong>Real-Time Airspace and Advisories:</strong> Stay updated with real-time airspace information and aviation advisories such as flight restrictions, airports, hospitals, stadiums, and more. Plan missions and assess risks with access to planning tools and weather data.
                                </li>
                                <li>
                                    <strong>Documentation Management:</strong> Upload and store manuals and documentation for easy access and revision control. Keep your team informed and up-to-date on critical operational procedures.
                                </li>
                                <li>
                                    <strong>Custom Checklists and Mission Data Sheets:</strong> Create custom checklists with time/date stamps and authenticated user signatures. Tailor UAS TrackPro to your operational requirements with customizable risk assessments and ensure compliance with regulations.
                                </li>
                                <li>
                                    <strong>Comprehensive Component Tracking:</strong> Track an unlimited number of components, including airframe, propellers, motors, landing gear, flight control systems, and more. Aircraft status and detailed logbooks provide a permanent archive for maintenance and inspection records.
                                </li>
                                <li>
                                    <strong>Data Security and Ownership:</strong> All data created and stored on UAS TrackPro belongs to your organization. Benefit from AES 256 encrypted databases to ensure data security and access only for authorized users.
                                </li>
                            </ol>
                            <p style="color: #333;">Experience the future of UAS tracking and reporting with UAS TrackPro. Maximize operational efficiency, safety, and compliance with our advanced software solution.</p>
                            </div>
                          
    
                        <div class="signature">
                            <!-- <img src="assets/img/signature.png" alt="signature"> -->
                        </div>
                    </div>
                </div>
            </div>
    
        </div>
    </div>
</section>
<!-- End UAS Track Pro Area -->  