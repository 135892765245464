<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>Mobile Ground Control Station</h1>
        <ul>
            <li><a routerLink="/">Home</a></li>
            <li>GCS</li>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start Project Details Area -->
<section class="rugged-pc-area uk-rugged-pcs uk-section">
    <div class="uk-container">
        <div class="uk-grid uk-flex project-details">
            <div class="project-details-img uk-width-expand">
                <img src="assets/img/gcs1.jpg" alt="image">
            </div>

            <div class="item uk-width-1-5">
                <div class="project-details-info">
                    <ul>
                        <li><Span>Customer Name:</Span> Resolute ISR</li>
                        <li><Span>Category:</Span> Ground Control Station</li>
                        <li><Span>Date:</Span> 06/2021</li>
                        <li><Span>Status:</Span> Completed</li>
                        <!--<li><Span>Demo Link:</Span> <a href="#">www.gunter.com</a></li>-->
                        <li><Span>Tags:</Span> <a>UAV</a>, <a>UAS</a>, <a>Ground Control Station</a>, <a>Mobile</a>, <a>Powerful</a>, <a>Custom Development</a></li>
                        <li>
                            <Span>Share our project:</Span>

                            <ul>
                               <!-- <li><a href="#"><i class="flaticon-logo"></i></a></li>
                                <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                                <li><a href="#"><i class="flaticon-logo-1"></i></a></li>-->
                                <li><a href="https://www.linkedin.com/sharing/share-offsite/?url=https://thirium.com/gcs"><i class="flaticon-linkedin"></i></a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="rugged-pcs-desc">
            <h3>Mobile Ground Control Station</h3>
            <p>Our UAS system has been chosen by Resolute ISR as their primary mobile ground station for use in some of the most challenging environments in the world. 
                In addition to high-performance computing, we have integrated a Serial to Ethernet converter, networking switch, and complete PGCS (ground side Piccolo autopilot,
                Novatel GPS, etc.) to provide a complete, mobile solution for UAS operations.</p>

            <p>At only 33 pounds, this system is compact and portable, with all the equipment necessary to fly a military drone from the back of a pickup truck. 
                It is built to withstand harsh conditions, with a waterproof and dustproof design that can be closed for travel. It also features a built-in 17.3-inch 144Hz 
                monitor (optional), up to 16TB of solid-state storage, high-performance processor (Ryzen 7 3800X or better), gaming-level graphics (2060 or better), built-in 
                Wi-Fi (optional), and the ability to connect up to 4 monitors. It is carry-on size with wheels for convenience, and durable enough to be checked as luggage on 
                an airplane. Set up takes less than a minute, with all connectors easily accessible from the top panel.</p>
            
           

        </div>

        <div class="project-next-and-prev">
            <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-2@s">
                <div class="item">
                    <a href="rugged-pc" class="uk-button uk-button-default">Prev Project</a>
                </div>

                <div class="item uk-text-right">
                    <a href="future-projects" class="uk-button uk-button-default">Next Project</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Project Details Area -->
