
<!-- End Page Title Area -->

<!-- Start UAS Red Line Stream Details Area -->
<section class="services-details-area uk-services-details uk-section ">
    <div class="uk-container">
        <article class="uk-article services-details">
            <div class="uk-grid uk-flex">

                <div class="uk-sidebar uk-width-1-5 uk-flex-first@l uk-first-column">
                    <app-servicesmenu></app-servicesmenu>
                    
                    <div class="widget widget_download">
                        <ul>
                            <!-- You can add download links or other widgets here -->
                        </ul>
                    </div>
                </div>

                <div class="inner uk-width-expand">
                    <div class="services-details-desc">
                        <!-- Custom Software Development Section -->
                        <div class="custom-software-section">
                            <h3>Thirium's Custom UAS Software Development</h3>
                            <p>At Thirium, we recognize the unique challenges and requirements of the UAS industry. With our deep expertise and experience, we offer custom software development tailored specifically for UAS operational and technical needs. Our solutions are designed to enhance the efficiency, safety, and capabilities of your UAS operations.</p>
                            
                            <p>Our offerings encompass a wide range of software solutions, including:</p>
                            <ul>
                                <li>Operations checklists to ensure systematic and safe UAS deployments.</li>
                                <li>Manuals and document tracking systems to maintain and manage critical operational guidelines and records.</li>
                                <li>Advanced video streaming solutions, like our Red Line Stream, to provide real-time, high-quality video feeds.</li>
                                <li>Custom plugins for the Piccolo autopilot system, enhancing its capabilities and integrating it seamlessly with other systems.</li>
                                <li>Other UAS-related software and plugins tailored to your specific needs.</li>
                            </ul>
                        </div>
                        <!-- End of Custom Software Development Section -->

                        <!-- In-house Solutions Introduction -->
                        <div class="in-house-solutions-intro">
                            <p>Both the Red Line Stream and UAS Track Pro are prime examples of our in-house solutions, meticulously crafted to cater to our operational needs. While these solutions are available to select customers, their primary purpose is to serve our internal requirements. They stand as a testament to our capabilities, showcasing our prowess in developing advanced, tailored solutions for the UAS industry.</p>
                        </div>
                        <!-- End of In-house Solutions Introduction -->

                        <br>
                        <div class="separate ">
                            <div class="br-line"></div>
                        </div>

                        <h3>Introducing Red Line</h3>
                        <p style="margin-bottom: -50;">Our cutting-edge streaming solution with dual AES 256 encryption tailored specifically for the UAS industry.</p>
                        <div class="item" style="margin-bottom: 30px;"> <!-- Added margin-bottom -->
                            <div class="about-img uk-position-relative">
                                <div class="uk-position-relative uk-visible-toggle uk-light custom-slider" tabindex="-1" uk-slider>

                                    <ul class="uk-slider-items uk-child-width-1-1">
                                        <li>
                                            <img src="assets/img/Red Line/1.png" alt="image" style="max-width: 100%;">
                                        </li>
                                        <li>
                                            <img src="assets/img/Red Line/2.png" alt="image" style="max-width: 100%;">
                                        </li>
                                        <li>
                                            <img src="assets/img/Red Line/3.png" alt="image" style="max-width: 100%;">
                                        </li>
                                        <li>
                                            <img src="assets/img/Red Line/4.png" alt="image" style="max-width: 100%;">
                                        </li>
                                        <li>
                                            <img src="assets/img/Red Line/5.png" alt="image" style="max-width: 100%;">
                                        </li>
                                        <li>
                                            <img src="assets/img/Red Line/6.png" alt="image" style="max-width: 100%;">
                                        </li>
                                        <li>
                                            <img src="assets/img/Red Line/7.png" alt="image" style="max-width: 100%;">
                                        </li>
                                        <li>
                                            <img src="assets/img/Red Line/8.png" alt="image" style="max-width: 100%;">
                                        </li>
                                    </ul>
                                    <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
                                    <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slider-item="next"></a>
                                </div>
                            </div>
                        </div>

                        <h3>Key Features:</h3>
                        <ol>
                            <li><strong>Superior Video Quality:</strong> Enjoy high-quality video streaming that delivers exceptional clarity and consistency, even across long distances of up to 4,000 miles. Red Line ensures that every detail is captured with precision.</li>
                            <li><strong>Robust AES256 Security:</strong> We prioritize the security of your UAS video communication. With dual AES 256 encryption, Red Line provides robust data protection, giving you peace of mind and safeguarding your sensitive information.</li>
                            <li><strong>Enhanced Situational Insight:</strong> Red Line empowers UAS operations with increased situational awareness, enabling better decision-making capabilities. Stay informed and make critical judgments based on real-time information.</li>
                            <li><strong>Instant Team Chat:</strong> Facilitate seamless collaboration within your team using Red Line's real-time chat feature. Enhance communication and streamline decision-making processes, ensuring efficient coordination during UAS operations.</li>
                            <li><strong>Multi-Stream Capability:</strong> Manage multiple live streams simultaneously with Red Line's multi-stream capability. Cover a wide range of UAS operational scenarios, allowing comprehensive monitoring and control.</li>
                        </ol>
                        <p>Red Line revolutionizes UAS video streaming, providing industry-leading features and functionality tailored to meet the unique needs of the UAS industry. Experience the future of UAS streaming with Red Line.</p>
                    </div>
                </div>
            </div>
        </article>
    </div>
</section>
<!-- End UAS Red Line Stream Details Area -->
<div class="separate ">
    <div class="br-line"></div>
</div>
<app-uastrackpro></app-uastrackpro>
<app-contact></app-contact>