<div class="widget service_list">

    <!-- Services Category -->
    <h4>Services</h4>
    <ul>
        <!-- <li><a routerLink="/custom-development" routerLinkActive="active">Custom GCS Development <i class="flaticon-right"></i></a></li> -->
        <li><a routerLink="/integrations" routerLinkActive="active">Engineering & Integrations <i class="flaticon-right"></i></a></li>
        <li><a routerLink="/uaspilotservices" routerLinkActive="active">UAS Pilot Services <i class="flaticon-right"></i></a></li>
        <li><a routerLink="/training" routerLinkActive="active">Training <i class="flaticon-right"></i></a></li>
        <li><a routerLink="/standardization" routerLinkActive="active">Standardization <i class="flaticon-right"></i></a></li>
        <li><a routerLink="/consulting" routerLinkActive="active">Consulting <i class="flaticon-right"></i></a></li>
    </ul>

    <!-- Products Category -->
    <h4>Products</h4>
    <ul>
        <li><a routerLink="/vulture" routerLinkActive="active">Unmanned Aerial Systems (UAS) <i class="flaticon-right"></i></a></li>
        <li><a routerLink="/uastech" routerLinkActive="active">UAS Payloads <i class="flaticon-right"></i></a></li>
        <li><a routerLink="/gcs" routerLinkActive="active">Ground Control Stations <i class="flaticon-right"></i></a></li>
        <li><a routerLink="/software-development" routerLinkActive="active">Software Solutions <i class="flaticon-right"></i></a></li>
    </ul>

</div>
