<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>About</h1>
        <ul>
            <li><a routerLink="/">Home</a></li>
            <li>About</li>
        </ul>
    </div>
</section>

<section class="uk-about about-area uk-section">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="about-content">
                    <div class="uk-section-title section-title">
                        <span>About Us</span>
                        <h2>Empowering the Future of Unmanned Aviation</h2>
                        <div class="bar"></div>
                    </div>

                    <div class="about-text">
                        <div class="icon">
                            <i class="flaticon-quality"></i>
                        </div>
                        <h3>Thirium Inc. - Pushing Boundaries</h3>
                        <p>Thirium Inc. is dedicated to empowering the future of unmanned aviation through cutting-edge design, exceptional functionality, and a commitment to continuous development. We push the boundaries of what's possible in the industry, making it more than just theory—it's Thirium.</p>
                        <p>Thirium Inc. is a Tennessee Corporation with multiple founders and investors. Our team consists of over 8+ full-time employees, investors, and contractors who are experts in UAS operations, technology, finance, and administration.</p>
                        <p>We hold original equipment manufacturer status and intellectual property rights for our rugged computer systems, ground control stations (GCS), Thirium UAS system, UAS Track Pro Software, Red Line Software, and other products.</p>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="about-img">
                     <img src="assets/img/Thirium Vulture/1.jpg" class="about-img1 smaller-image" alt="about-img">
                    <!-- <img src="assets/img/about2.png" class="about-img2" alt="about-img"> -->
                    <!-- <img src="assets/img/1.png" class="shape-img" alt="shape"> -->
                </div>
            </div>
        </div>
    </div>
</section>



<!-- Start Team Area -->
<section id="team" class="team-area uk-team uk-section">
    <div class="uk-container">
        <div class="uk-section-title section-title">
            <span>Meet Our Experts</span>
            <h2>Executive Leadership & Stakeholders</h2>
            <div class="bar"></div>
        </div>

        <div uk-slider="sets: true; autoplay: true; autoplay-interval: 5000">

            <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1">
                <ul class="uk-slider-items uk-child-width-1-2 uk-child-width-1-3@m uk-grid">
                    <!-- Austin Lawson - CEO -->
                    <li>
                        <div class="uk-card uk-card-default">
                            <div class="uk-card-media-top">
                                <img class="team-img" src="assets/img/Team/austin.jpg" alt="Person 1">
                            </div>
                            <div class="uk-card-body">
                                <h3 class="uk-card-title">Austin Lawson</h3>
                                <p>Co-Founder & CEO</p>
                                <button class="uk-button uk-button-default" uk-toggle="target: #modal-austin">Read More</button>
                            </div>
                        </div>

                        <!-- This is the modal -->
                        <div id="modal-austin" uk-modal>
                            <div class="uk-modal-dialog uk-modal-body">
                                <h2 class="uk-modal-title">Austin Lawson</h2>
                                <p>Austin Lawson is the Founder and CEO of Thirium, an American technology company focused on custom products and services in the Unmanned Aerial Vehicles (UAV) industry. Previously, Lawson served as a Chief Warrant Officer 3 Helicopter Pilot in the US Army and spent more than five years providing UAV operational support for Tier 1 US Special Operational in Syria, Iraq, and Lebanon. He is a member of the Forbes Technology Council and has contributed to publications such as Forbes, Entrepreneur Magazine, and Foundr Magazine.</p>

                                <div class="uk-text-right">
                                    <ul class="uk-iconnav">
                                        <li><a href="https://twitter.com/akaAustinLawson" uk-icon="icon: twitter"></a></li>
                                        <li><a href="https://www.linkedin.com/in/austinlawson/" uk-icon="icon: linkedin"></a></li>
                                        <li><a href="https://www.instagram.com/akaaustinlawson" uk-icon="icon: instagram"></a></li>
                                    </ul>
                                    <button class="uk-button uk-button-default uk-modal-close" type="button">Close</button>
                                </div>
                            </div>
                        </div>
                    </li>
                    <!-- Jon Malnero - COO -->
                    <li>
                        <div class="uk-card uk-card-default">
                            <div class="uk-card-media-top">
                                <img class="team-img" src="assets/img/Team/jon.jpg" alt="Person 1">
                            </div>
                            <div class="uk-card-body">
                                <h3 class="uk-card-title">Jon Malnero</h3>
                                <p>Co-Founder & COO</p>
                                <button class="uk-button uk-button-default" uk-toggle="target: #modal-jon">Read More</button>
                            </div>
                        </div>

                        <!-- This is the modal -->
                        <div id="modal-jon" uk-modal>
                            <div class="uk-modal-dialog uk-modal-body">
                                <h2 class="uk-modal-title">Jon Malnero</h2>
                                <p>Jonathan Malnero is a highly experienced professional with over 10 years of expertise in the UAS and business sectors. As the COO at Thirium, he spearheads strategic initiatives to drive operational excellence and stimulate organizational growth. Having a robust background in both Department of Defense (DoD) and civilian sectors, Jonathan brings a wealth of experience to the team. He has demonstrated exceptional leadership skills by successfully leading overseas sites, and his extensive engineering and flight experience enables him to navigate complex challenges with ease. </p>
                                <div class="uk-text-right">
                                    <ul class="uk-iconnav">
                                        <!-- <li><a href="https://twitter.com/akaAustinLawson" uk-icon="icon: twitter"></a></li> -->
                                        <li><a href="https://www.linkedin.com/in/jonathan-r-malnero-970783132/?trk=public_profile_browsemap" uk-icon="icon: linkedin"></a></li>
                                        <!-- <li><a href="https://www.instagram.com/akaaustinlawson" uk-icon="icon: instagram"></a></li> -->
                                    </ul>
                                    <button class="uk-button uk-button-default uk-modal-close" type="button">Close</button>
                                </div>
                            </div>
                        </div>
                    </li>
                    <!-- Alana Vick - CFO -->
                    <li>
                        <div class="uk-card uk-card-default">
                            <div class="uk-card-media-top">
                                <img class="team-img" src="assets/img/Team/alana.jpg" alt="Person 1">
                            </div>
                            <div class="uk-card-body">
                                <h3 class="uk-card-title">Alana K. Vick, CPA</h3>
                                <p>Chief Financial Officer</p>
                                <button class="uk-button uk-button-default" uk-toggle="target: #modal-alana">Read More</button>
                            </div>
                        </div>

                        <!-- This is the modal -->
                        <div id="modal-alana" uk-modal>
                            <div class="uk-modal-dialog uk-modal-body">
                                <h2 class="uk-modal-title">Alana Vick, CPA</h2>
                                <p>Alana Vick is a distinguished Certified Public Accountant and CFO, bringing over 15 years of experience in public accounting from serving Silicon Valley tech giants to early-stage startups. With a Bachelor of Science in Business Administration – Accounting from the University of Arizona, Alana's expertise encompasses tax compliance, stock option planning, and strategic financial management. Having managed an extensive portfolio of clients, including closely-held businesses and highly compensated executives, she skillfully navigates tax regulations across all 50 states. Based in Powell, TN, Alana leverages her vast knowledge and commitment to serve as a trusted financial leader in the tech industry. </p>
                                <div class="uk-text-right">
                                    <ul class="uk-iconnav">
                                        <!-- <li><a href="https://twitter.com/akaAustinLawson" uk-icon="icon: twitter"></a></li> -->
                                        <li><a href="https://www.linkedin.com/in/alanakvickcpa/" uk-icon="icon: linkedin"></a></li>
                                        <li><a href="https://www.instagram.com/alanakvickcpa/" uk-icon="icon: instagram"></a></li>
                                    </ul>
                                    <button class="uk-button uk-button-default uk-modal-close" type="button">Close</button>
                                </div>
                            </div>
                        </div>
                    </li>
                    <!-- Julia Hurley - Investor -->
                    <li>
                        <div class="uk-card uk-card-default">
                            <div class="uk-card-media-top">
                                <img class="team-img" src="assets/img/Team/julia.jpg" alt="Person 1">
                            </div>
                            <div class="uk-card-body">
                                <h3 class="uk-card-title">Julia Hurley</h3>
                                <p>US Investor</p>
                                <button class="uk-button uk-button-default" uk-toggle="target: #modal-julia">Read More</button>
                            </div>
                        </div>

                                    <!-- This is the modal -->
                    <div id="modal-julia" uk-modal>
                        <div class="uk-modal-dialog uk-modal-body">
                            <h2 class="uk-modal-title">Julia Hurley</h2>
                            <p>Julia Hurley, the Owner and Broker of JustHomesGroup Real Estate, has added several avenues of investing to her personal portfolio. From rental properties and crypto, to AI editing software and Thirium, she recognizes the value and importance of supporting creativity and innovation in all avenues of business. </p>
                            <div class="uk-text-right">
                                <ul class="uk-iconnav">
                                    <li><a href="https://www.instagram.com/JustHomesGroup" uk-icon="icon: instagram"></a></li>
                                    <li><a href="https://www.tiktok.com/@TheJuliaHurley" uk-icon="icon: tiktok"></a></li>
                                    <li><a href="https://www.JustHomesGroup.com" uk-icon="icon: home"></a></li>
                                    <li><a href="https://www.GoSeeDrJoe.com" uk-icon="icon: world"></a></li>
                                    
                                </ul>
                                <button class="uk-button uk-button-default uk-modal-close" type="button">Close</button>
                            </div>
                        </div>
                    </div>

                    <!--CTO - Unknown -->
                    <li>
                        <div class="uk-card uk-card-default">
                            <div class="uk-card-media-top">
                                <img class="team-img" src="assets/img/Team/question.jpg" alt="Person 1">
                            </div>
                            <div class="uk-card-body">
                                <h3 class="uk-card-title">Open Position</h3>
                                <p>Chief Technology Officer</p>
                                <button class="uk-button uk-button-default" uk-toggle="target: #modal-cto">Read More</button>
                            </div>
                        </div>

                        <!-- This is the modal -->
                        <div id="modal-cto" uk-modal>
                            <div class="uk-modal-dialog uk-modal-body">
                                <h2 class="uk-modal-title">Chief Technology Officer</h2>
                                <p>We are exploring the opportunity to expand our executive leadership team with a CTO position. This role will be key to our ongoing growth and development in the UAS industry. We are interested in innovative technology experts with a strong background in creating tech strategies, overseeing technological resources, and driving growth opportunities. While we are currently in the exploratory phase and not actively hiring, we are always open to engaging with talented individuals who could make a significant impact in our organization. For more information on potential opportunities, we invite you to visit our <a href="/careers">Careers Page.</a></p>

                                <div class="uk-text-right">
                                    <ul class="uk-iconnav">
                                        <!-- <li><a href="https://twitter.com/akaAustinLawson" uk-icon="icon: twitter"></a></li>
                                        <li><a href="https://www.linkedin.com/in/austinlawson/" uk-icon="icon: linkedin"></a></li>
                                        <li><a href="https://www.instagram.com/akaaustinlawson" uk-icon="icon: instagram"></a></li> -->
                                    </ul>
                                    <button class="uk-button uk-button-default uk-modal-close" type="button">Close</button>
                                </div>
                            </div>
                        </div>
                    </li>
                    <!-- Director of BD -->
                    <li>
                        <div class="uk-card uk-card-default">
                            <div class="uk-card-media-top">
                                <img class="team-img" src="assets/img/Team/question.jpg" alt="Person 1">
                            </div>
                            <div class="uk-card-body">
                                <h3 class="uk-card-title">Open Position</h3>
                                <p>Director of Business Development</p>
                                <button class="uk-button uk-button-default" uk-toggle="target: #modal-bd">Read More</button>
                            </div>
                        </div>

                        <!-- This is the modal -->
                        <div id="modal-bd" uk-modal>
                            <div class="uk-modal-dialog uk-modal-body">
                                <h2 class="uk-modal-title">Open Position</h2>
                                <p>We are contemplating adding a Director of Business Development to our executive leadership team. This potential role would be key to our expansion in the UAS industry. We envision this person as someone with a proven track record of identifying business opportunities, cultivating partnerships, and driving growth. While we are exploring possibilities and not actively hiring, we're open to engaging with professionals who could contribute to our strategic vision. For more information, visit our <a href="/careers">Careers Page.</a></p>




                                <div class="uk-text-right">
                                    <ul class="uk-iconnav">
                                        <!-- <li><a href="https://twitter.com/akaAustinLawson" uk-icon="icon: twitter"></a></li>
                                        <li><a href="https://www.linkedin.com/in/austinlawson/" uk-icon="icon: linkedin"></a></li>
                                        <li><a href="https://www.instagram.com/akaaustinlawson" uk-icon="icon: instagram"></a></li> -->
                                    </ul>
                                    <button class="uk-button uk-button-default uk-modal-close" type="button">Close</button>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>

                <a class="uk-position-center-left uk-position-small" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
                <a class="uk-position-center-right uk-position-small" href="#" uk-slidenav-next uk-slider-item="next"></a>
            </div>

            <ul class="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>
        </div>
    </div>
</section>
<!-- End Team Area -->





 





<!-- Start Feedback Area -->
<section id="clients" class="feedback-area uk-dark uk-section uk-feedback">
    <div class="uk-container">
      <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
        <div class="item">
          <div class="feedback-img">
            <img src="assets/img/brandtest.png" alt="image">
          </div>
        </div>
        <div class="item">
          <div class="uk-section-title section-title">
            <span>Let's Talk</span>
            <h2>Get in Touch</h2>
          </div>
  
          <form #contactForm id="contactForm" action="https://formspree.io/f/mvonjnvr" method="POST">
            <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
              <div class="item uk-margin">
                <input type="text" class="uk-input" name="name" id="name" placeholder="Name">
              </div>
              <div class="item uk-margin">
                <input type="email" class="uk-input" name="email" id="email" placeholder="Email">
              </div>
              <div class="item uk-margin">
                <input type="text" class="uk-input" placeholder="Phone">
              </div>
              <div class="item uk-margin">
                <input type="text" class="uk-input" name="subject" id="subject" placeholder="Subject">
              </div>
            </div>
            <div class="item">
              <textarea name="message" class="uk-textarea" id="message" cols="30" rows="4" placeholder="Your Message"></textarea>
            </div>
            <input type="hidden" name="_replyto" value="alawson@thirium.com">
            <button type="submit" class="uk-button uk-button-default">Submit Message</button>
          </form>
        </div>
      </div>
    </div>
  </section>
<!-- End Feedback Area -->