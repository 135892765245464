<!-- Start About Us Section -->
<div class="uk-section uk-dark" style="background: #000 !important;">
    <div class="uk-container">
        <h2 class="uk-text-center" style="color: #fff !important;">Technical Mastery. Operational Dominance.</h2>
        <p class="uk-text-center" style="color: #fff !important;">
            At Thirium, we've forged a reputation as the gold standard in the UAS industry. Our dual strengths in technical innovation and operational excellence have positioned us as the trusted partner for some of the industry's most significant and high-profile missions.
            With a legacy of success both in the lab and on the battlefield, we're redefining the boundaries of UAS capabilities.
        </p>
        
        <div class="uk-grid uk-child-width-1-2@m uk-child-width-1-1@s">
            <!-- Operational Expertise Section -->
            <div class="uk-card uk-card-default uk-card-body" style="background: #111 !important; color: #fff !important;">
                <h3 class="uk-card-title">Operational Expertise</h3>
                <p>Our operational credentials are unparalleled. With thousands of flight hours across the globe's most challenging environments, we've consistently delivered where others couldn't. From the dense urban landscapes of Syria to the rugged terrains of Iraq and Lebanon, we've navigated scenarios filled with jamming and spoofing threats. Our 14+ operational deployments in support of elite US units stand as a testament to our unwavering commitment to mission success.</p>
            </div>
            <div class="video-container">
                <div class="video-background">
                    <video id="delayedVideo" onloadedmetadata="this.muted=true" loop preload="auto" poster="assets/img/Operations Images/opsplaceholder.png" autoplay>
                        <source src="assets/video/ops.mp4" type="video/mp4">
                    </video>
                    <button class="play-pause-btn">Pause</button>
                </div>
            </div>
            
            <!-- Technical Prowess Section -->
            <div class="image-container">
                <img src="assets/img/Tech/pcc.png" alt="Technical Image">
            </div>
            <div class="uk-card uk-card-default uk-card-body" style="background: #111 !important; color: #fff !important;">
                <h3 class="uk-card-title">Technical Prowess</h3>
                <p>Our technical expertise is the backbone of our operational success. Our deep understanding of the Piccolo suite of systems, including the Elite, SL, and Nano models, empowers us to develop procedures, make informed decisions, and integrate payloads with ease. This profound knowledge, coupled with our adaptability and in house engineering team, ensures that we consistently meet the nuanced demands of each mission, every time.</p>

            </div>
        </div>
        
        
        <blockquote class="uk-text-center" style="color: #fff !important;">
            <p>"Thirium has been a game changer for our operations. Their expertise and dedication to excellence have made a significant impact on our success."</p>
            <footer>- Anonymous</footer>
        </blockquote>
        <div class="uk-text-center">
            <a href="/contact" class="uk-button uk-button-default">Contact Us</a>
        </div>
    </div>
</div>
<!-- End About Us Section -->



