<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>Consulting</h1>
        <ul>
            <li><a routerLink="/">Home</a></li>
            <li>Services Details</li>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area uk-services-details uk-section">
    <div class="uk-container">
        <article class="uk-article services-details">
            <div class="uk-grid uk-flex">

                <div class="uk-sidebar uk-width-1-5 uk-flex-first@l uk-first-column">
                    <!-- <div class="widget widget_search">
                         <form>
                             <input type="text" class="uk-input" placeholder="Search here...">
                             <button type="submit"><i class="flaticon-search"></i></button>
                         </form>
                     </div>-->
 
                 <app-servicesmenu></app-servicesmenu>
 
                     <div class="widget widget_download">
                         <!--<h3 class="widget-title">Download</h3>
                         <div class="bar"></div>-->
 
                         <ul>
                             <!--<li><a href="https://www.dropbox.com/s/93jd2v7hy64ii8f/Advanced%20Computers%20Logo.png?dl=0">Capabilities Brief <i class="flaticon-edit"></i></a></li>-->
                             <!--<li><a href="#">Our Iso Certificate <i class="flaticon-edit"></i></a></li>
                             <li><a href="#">Great Technology <i class="flaticon-edit"></i></a></li>-->
                         </ul>
                     </div>
                 </div>

                 
                <div class="inner uk-width-expand">
                    <div class="services-details-desc">
                        <h3>Consulting</h3>
                        

                        <p>Developing and implementing successful UAS systems and operational programs can be a complex and challenging process. 
                            That's where Thirium comes in. Our team has extensive experience supporting elite military teams in some of the toughest 
                            environments on earth. We have seen it all, and we know what it takes to build effective and sustainable UAS programs.</p>

                            <p>If you are looking to take your UAS platform to the next level, we encourage you to leverage our expertise and experience. 
                                We can help you overcome the challenges and achieve your goals, no matter how ambitious they may be.</p>
                                

                        <div class="services-image-slides owl-carousel owl-theme">
                            <div class="item">
                                <img src="assets/img/consulting.jpg" alt="img">
                            </div>

                           <!-- <div class="item">
                                <img src="assets/img/blog-details.jpg" alt="img">
                            </div>

                            <div class="item">
                                <img src="assets/img/blog-details.jpg" alt="img">
                            </div>-->
                        </div>
                        <h3>Not sure what you need? -- Ask about the Thirium Evaluation</h3>
                        <p>Our team comes to you and evaluates your platform, processes, procedures, systems, and personnel. We will then provide you with a comprehensive report detailing the weaknesses and improvement areas. The objective is to bring to light the areas that could be holding your company and UAS system back.</p>

                        <!--<ul class="services-features-list">
                            <li><i class="flaticon-tick"></i> Tier 1 experience</li>
                            <li><i class="flaticon-tick"></i> Light Weight</li>
                            <li><i class="flaticon-tick"></i> Extreme Graphical Capabilities</li>
                            <li><i class="flaticon-tick"></i> 144Hz Monitors</li>
                            <li><i class="flaticon-tick"></i> Waterproof & Dustproof</li>
                            <li><i class="flaticon-tick"></i> Custom Integrations Available</li>
                        </ul>-->

                        <!--<blockquote class="blockquote">
                            <p>Sed ut perspiciatis undeomnis iste natus error sit voluptatem accusantium dolore Totam rem aperiam with a long list of products and never ending customer support.</p>
                        </blockquote>-->

                       <!--<h3>Benefits of Working with Thirium</h3>
                        <p>We send our operational experts to you and ensure the system being built fits both your operational style, tempo and is both sustainable and meets all future operational objectives.   </p>
                        <ul class="services-features-list">
                            <li><i class="flaticon-tick"></i> Low Lead Times</li>
                            <li><i class="flaticon-tick"></i> On Site & Remote Support</li>
                            <li><i class="flaticon-tick"></i> Fast Prototype Development</li>
                            <li><i class="flaticon-tick"></i> Extreme Durablitiy & Performance Testing</li>
                            
                        </ul>--> 
                        <br>
                        <h3>FAQ's</h3>
                        <div class="our-work-benefits">
                            <ul class="accordion">
                                <li class="accordion-item">
                                    <a class="accordion-title active" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Do you have a list of consulting options?
                                    </a>

                                    <p class="accordion-content show">No. Each situation and company is extremely unique. There isn't anything standard about consulting. The best course of action is to get in touch with our team to discuss your needs. If we both feel that Thirium has the experience to help, we'll prepare a custom plan based on your unique situation. </p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Can you give us an analysis of our competition?
                                    </a>

                                    <p class="accordion-content">No. We work with many companies and it's possible that your competition could be our customer. We also don't disclose
                                        who our current customers are without prior approval from them, so please don't ask. </p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        How long is the typical consulting engagement?
                                    </a>

                                    <p class="accordion-content">There honestly isn't a standard length. It depends on where you are as a program and your current needs. It's ultimately up to you and the value you want out of the relationship. </p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Are you open to partnerships?
                                    </a>

                                    <p class="accordion-content">We are always open to mutually beneficial partnerships. Please contact us to discuss what you have in mind. </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                
            </div>
        </article>
    </div>
</section>
<!-- End Services Details Area -->
