<!-- Start Feedback Area -->
<section id="clients" class="feedback-area uk-dark uk-section uk-feedback">
    <div class="uk-container">
      <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
        <div class="item">
          <div class="feedback-img">
            <img src="assets/img/brandtest.png" alt="image">
          </div>
        </div>
        <div class="item">
          <div class="uk-section-title section-title">
            <span>Let's Talk</span>
                    <h2>Get in Touch</h2>
                    <p>We value your feedback and inquiries. Please fill in the form below and we will respond as quickly as possible. Alternatively, feel free to email or call us.</p>
                </div>
  
          <form #contactForm id="contactForm" action="https://formspree.io/f/mvonjnvr" method="POST">
            <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
              <div class="item uk-margin">
                <input type="text" class="uk-input" name="name" id="name" placeholder="Name">
              </div>
              <div class="item uk-margin">
                <input type="email" class="uk-input" name="email" id="email" placeholder="Email">
              </div>
              <div class="item uk-margin">
                <input type="text" class="uk-input" placeholder="Phone">
              </div>
              <div class="item uk-margin">
                <input type="text" class="uk-input" name="subject" id="subject" placeholder="Subject">
              </div>
            </div>
            <div class="item">
              <textarea name="message" class="uk-textarea" id="message" cols="30" rows="4" placeholder="Your Message"></textarea>
            </div>
            <input type="hidden" name="_replyto" value="alawson@thirium.com">
            <button type="submit" class="uk-button uk-button-default">Submit Message</button>
          </form>
          <div class="uk-margin">
            <p><strong>Email:</strong> admin@thirium.com</p>
            <!-- <p><strong>Phone:</strong> +1 234 567 8900</p> -->
        </div>
      </div>
    </div>
    </div>
  </section>
<!-- End Feedback Area -->