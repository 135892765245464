import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-standardization',
  templateUrl: './standardization.component.html',
  styleUrls: ['./standardization.component.scss']
})
export class StandardizationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
