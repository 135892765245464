<!-- Start Footer Area -->
<footer class="footer-area {{location == '/' ? '' : 'uk-dark'}} uk-footer">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-4@m uk-child-width-1-2@s">
            <div class="item">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a routerLink="/">
                            <img src="assets/img/logo2.png" alt="logo">
                        </a>
                    </div>
                    <p>It's not theory. It's Thirium.</p>
                </div>
            </div>

            <div class="item">
                <div class="single-footer-widget">
                    <h3>Investors</h3>
                    <div class="bar"></div>

                    <div class="location">
                        <p>We are taking meetings with investors on a limtied basis. </p>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="single-footer-widget">
                    <h3>Location</h3>
                    <div class="bar"></div>

                    <div class="location">
                        <p>454 Parker Drive <br> Ozark, AL 36360 <br> United States</p>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="single-footer-widget">
                    <h3>Contact</h3>
                    <div class="bar"></div>

                    <ul class="contact-info">
                        <li><a href="mailto:hello@baylin.com">admin@thirium.com</a></li>
                        <li><a href="#">www.thirium.com</a></li>
                        <!--<li><a href="tel:+4232158913">(423) 215-8913</a></li>-->
                    </ul>
                    <!--<ul class="social">
                        <li><a href="#" target="_blank"><i class="flaticon-logo"></i></a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-linkedin"></i></a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-logo-1"></i></a></li>
                    </ul>-->
                </div>
            </div>
        </div>

        <div class="copyright-area">
            <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-2@s">
                <div class="item">
                    <p>© 2023 Thirium Inc. All Rights Reserved, Powered by Innovation</p>
                </div>

                <!--<div class="item">
                    <ul>
                        <li><a href="#">Terms & Conditions</a></li>
                        <li><a href="#">Privacy Policy</a></li>
                    </ul>
                </div>-->
            </div>
            <div class="go-top"><i class="flaticon-chevron"></i></div>
        </div>
    </div>

    <div class="br-line"></div>
    <div class="footer-shape1"><img src="assets/img/footer-shape1.png" alt="shape"></div>
    <div class="footer-shape2"><img src="assets/img/footer-shape2.png" alt="shape"></div>
</footer>
<!-- End Footer Area -->
