<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>Integrations</h1>
        <ul>
            <li><a routerLink="/">Home</a></li>
            <li>Services Details</li>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area uk-services-details uk-section">
    <div class="uk-container">
        <article class="uk-article services-details">
            <div class="uk-grid uk-flex">

                <div class="uk-sidebar uk-width-1-5 uk-flex-first@l uk-first-column">
                    <!-- <div class="widget widget_search">
                         <form>
                             <input type="text" class="uk-input" placeholder="Search here...">
                             <button type="submit"><i class="flaticon-search"></i></button>
                         </form>
                     </div>-->
 
                <app-servicesmenu></app-servicesmenu>
 
                     <div class="widget widget_download">
                         <!--<h3 class="widget-title">Download</h3>
                         <div class="bar"></div>-->
 
                         <ul>
                             <!--<li><a href="https://www.dropbox.com/s/93jd2v7hy64ii8f/Advanced%20Computers%20Logo.png?dl=0">Capabilities Brief <i class="flaticon-edit"></i></a></li>-->
                             <!--<li><a href="#">Our Iso Certificate <i class="flaticon-edit"></i></a></li>
                             <li><a href="#">Great Technology <i class="flaticon-edit"></i></a></li>-->
                         </ul>
                     </div>
                 </div>
                <div class="inner uk-width-expand">
                    <div class="services-details-desc">
                        <h3>UAS Pilot Services</h3>
                        

                        <p>As you may be aware, finding highly skilled and proficient UAS pilots can be a challenge in today's market. 
                            Hiring inexperienced or underqualified pilots can lead to costly mistakes and setbacks, such as wasted resources and damaged equipment.</p>

                            <p>At Thirium, we offer contract pilot services to help you quickly meet operational goals and objectives. 
                                Our team consists of experienced, reliable pilots who are ready to support your needs. To further assist with the growth of your program, 
                                we also offer a "try before you buy" initiative, which gives you the opportunity to test out our pilots and then hire them full time if
                                they meet the longterm needs of your program.
                                This allows you to assess their expertise and professionalism firsthand, and make an informed decision about whether they are the right fit for 
                                your organization.</p>

                                <p>Don't take unnecessary risks with your hiring process - let us provide you with top-quality UAS pilots through our contract 
                                    services or "try before you buy" initiative.  </p>

                        <div class="services-image-slides owl-carousel owl-theme">
                            <div class="item">
                                <img src="assets/img/Thirium Vulture/2.jpg" alt="img">
                            </div>

                           <!-- <div class="item">
                                <img src="assets/img/blog-details.jpg" alt="img">
                            </div>

                            <div class="item">
                                <img src="assets/img/blog-details.jpg" alt="img">
                            </div>-->
                        </div>
                        <h3>Benefits of Thirium UAS Pilot Services</h3>
                        

                        <ul class="services-features-list">
                            <li><i class="flaticon-tick"></i> Proven Operators</li>
                            <li><i class="flaticon-tick"></i> Love a Pilot? Hire him/her</li>
                            <li><i class="flaticon-tick"></i> Low Flight Risk </li>
                            <li><i class="flaticon-tick"></i> Reduced overhead</li>
                            
                        </ul>

                        <!--<blockquote class="blockquote">
                            <p>Sed ut perspiciatis undeomnis iste natus error sit voluptatem accusantium dolore Totam rem aperiam with a long list of products and never ending customer support.</p>
                        </blockquote>-->

                        <br>
                        <h3>FAQ's</h3>
                        <div class="our-work-benefits">
                            <ul class="accordion">
                                <li class="accordion-item">
                                    <a class="accordion-title active" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        What kind of Piccolo experience do your operators have?
                                    </a>

                                    <p class="accordion-content show">The majority of our operators have multiple deployments overseas running real time operations with both fixed wing and VTOL aircraft. We have operators experienced with HQ, Piccolo II and the Elite with elevate autopilots.   </p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        What is the hourly rates associated with your pilots?
                                    </a>

                                    <p class="accordion-content">Depending on the level of requried expereience, our operators range from $65 to $200 per hour.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Can we really hire your operators?
                                    </a>

                                    <p class="accordion-content">Yes. While we hate to lose good operators, we encourage all employees to take opportunities to progress and take their careers to the next level.</p>
                                </li>

                               <!-- <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        What do you mean when you say "Training Provided"?
                                    </a>

                                    <p class="accordion-content">Once the integration is complete, our team will come and train your personnel on the newly integrated payload. We also provide full documentation sufficient for integration into a training program with little effort.  </p>
                                </li>-->
                            </ul>
                        </div>
                    </div>
                </div>

               
            </div>
        </article>
    </div>
</section>
<!-- End Services Details Area -->


