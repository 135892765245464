



<!-- Start Services Details Area -->
<section class="services-details-area uk-services-details uk-section">
  <div class="uk-container">
      <article class="uk-article services-details">
          <div class="uk-grid uk-flex">

              <div class="uk-sidebar uk-width-1-5 uk-flex-first@l uk-first-column">
                  <!-- Sidebar content -->
                  <app-servicesmenu></app-servicesmenu>
                  <!-- Other sidebar widgets can go here -->
              </div>

              

              <div class="inner uk-width-expand">
                  <div class="services-details-desc">

                <!-- Start Custom GCS Introduction Area -->
<section class="custom-gcs-intro uk-section">
  <div class="uk-container">
      <h2 class="uk-text-center">Ground Control Stations</h2>
      <p class="uk-text-center">
          At Thirium, we believe in precision-tailored solutions. Recognizing that no two missions are alike, we've departed from the one-size-fits-all approach. Instead, each of our Ground Control Stations (GCS) is uniquely crafted to suit. We don't offer a standard GCS. Every GCS begins as a development contract, meticulously designed and tested to meet the specific operational needs of our clients. We understand that every mission is unique, and a tailored solution often yields the best results. That's why we're committed to crafting custom GCS systems, ensuring each client gets the best fit for their specific needs.
      </p>
  </div>
</section>
<!-- End Custom GCS Introduction Area -->

<br>

                      <!-- <h3>Watch Our Video</h3> -->
                      <div class="video-wrapper">
                          <div style="padding: 62.35% 0 0 0; position: relative;">
                              <iframe src="https://player.vimeo.com/video/834914815?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen autoplay loop muted volume="0" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;" title="DualGCS" muted autoplay></iframe>
                          </div>
                          <script src="https://player.vimeo.com/api/player.js"></script>
                      </div><br>
                      <p>Our innovative solutions reached a pinnacle with a system that was proudly showcased at the Special Operations Forces Industry Conference (SOFIC) in both 2022 and 2023. Serving as a testament to our capabilities, this custom dual rugged PC exemplifies the heights achievable when we tailor solutions to specific client needs. It features two high-performance computers, each running a distinct operating system, providing unmatched versatility and compatibility across a broad spectrum of software applications. This system stands as a prime example of our commitment to pushing boundaries, with Thirium Engineering's dedicated team ensuring its top-tier performance and reliability through rigorous development and refinement.</p>


                      <!-- Image Gallery -->
                      <h3>Image Gallery</h3>
                      <div uk-slider="finite: true">
                          <div class="uk-position-relative">
                              <ul class="uk-slider-items uk-child-width-1-2 uk-child-width-1-4@s uk-grid-small uk-grid-match" uk-grid>
                                  <li *ngFor="let image of images">
                                      <a [href]="image.src" data-fancybox="gallery">
                                          <div class="uk-card uk-card-default uk-card-hover">
                                              <div class="uk-card-media-top">
                                                  <img [src]="image.thumb" [alt]="image.caption" class="fancybox__image" (click)="openLightbox(image)">
                                              </div>
                                          </div>
                                      </a>
                                  </li>
                              </ul>
                              <a class="uk-position-center-left uk-position-small" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
                              <a class="uk-position-center-right uk-position-small" href="#" uk-slidenav-next uk-slider-item="next"></a>
                          </div>
                          <ul class="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>
                      </div>
                  </div>
              </div>
          </div>
      </article>
  </div>
</section>
<!-- End Services Details Area -->

<div class="separate uk-dark">
  <div class="br-line"></div>
</div>
