<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>GCS Development</h1>
        <ul>
            <li><a routerLink="/">Home</a></li>
            <li>Services Details</li>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area uk-services-details uk-section">
    <div class="uk-container">
        <article class="uk-article services-details">
            <div class="uk-grid uk-flex">

                <div class="uk-sidebar uk-width-1-5 uk-flex-first@l uk-first-column">
                    <!-- <div class="widget widget_search">
                         <form>
                             <input type="text" class="uk-input" placeholder="Search here...">
                             <button type="submit"><i class="flaticon-search"></i></button>
                         </form>
                     </div>-->
 
                <app-servicesmenu></app-servicesmenu>
 
                     <div class="widget widget_download">
                         <!--<h3 class="widget-title">Download</h3>
                         <div class="bar"></div>-->
 
                         <ul>
                             <!--<li><a href="https://www.dropbox.com/s/93jd2v7hy64ii8f/Advanced%20Computers%20Logo.png?dl=0">Capabilities Brief <i class="flaticon-edit"></i></a></li>-->
                             <!--<li><a href="#">Our Iso Certificate <i class="flaticon-edit"></i></a></li>
                             <li><a href="#">Great Technology <i class="flaticon-edit"></i></a></li>-->
                         </ul>
                     </div>
                 </div>

                <div class="inner uk-width-expand">
                    <div class="services-details-desc">
                        <h3>GCS Development</h3>
                        

                        <p>While many companies are focused on developing futuristic systems that promise to revolutionize the industry, they often overlook the practical 
                            needs of their operators in the field. As a result, operators may be forced to rely on outdated equipment, such as Toughbook laptops, that are 
                            prone to crashing and slow to respond in critical situations. This can put your company's expensive UAS assets at risk, as operator oversight may 
                            be lost during crucial moments such as takeoff or landing.</p>

                            <p>At Thirium, we prioritize both functionality and technological advancement in everything we do. 
                                We understand the importance of having reliable, responsive equipment that can keep up with the demands of modern UAS operations.</p>

                        <div class="services-image-slides owl-carousel owl-theme">
                            <div class="item">
                                <img src="assets/img/Dual GCS/1.jpg" alt="img">
                            </div>

                           <!-- <div class="item">
                                <img src="assets/img/blog-details.jpg" alt="img">
                            </div>

                            <div class="item">
                                <img src="assets/img/blog-details.jpg" alt="img">
                            </div>-->
                        </div>
                        <h3>Get a Thirium System</h3>
                        <p>We build fast, durable, and effective systems that outperform even rack-mounted systems.</p>

                        <ul class="services-features-list">
                            <li><i class="flaticon-tick"></i> High Performance</li>
                            <li><i class="flaticon-tick"></i> Light Weight</li>
                            <li><i class="flaticon-tick"></i> Extreme Graphical Capabilities</li>
                            <li><i class="flaticon-tick"></i> 144Hz Monitors</li>
                            <li><i class="flaticon-tick"></i> Waterproof & Dustproof (Some Systems)</li>
                            <li><i class="flaticon-tick"></i> Custom Integrations Available</li>
                        </ul>

                        <!--<blockquote class="blockquote">
                            <p>Sed ut perspiciatis undeomnis iste natus error sit voluptatem accusantium dolore Totam rem aperiam with a long list of products and never ending customer support.</p>
                        </blockquote>-->

                        <h3>Benefits of Working with Thirium</h3>
                       
                        <p>At Thirium, we prioritize building systems that are tailored to each customer, rather than offering off-the-shelf ground control systems. Our systems are built to suit your unique operational style and tempo. We ensure that the system we provide is sustainable and meets all your future operational objectives. With Thirium, you can expect a customized solution that caters to your needs and empowers you to achieve your UAS operational goals.</p>

                        <ul class="services-features-list">
                            <li><i class="flaticon-tick"></i> Low Lead Times</li>
                            <li><i class="flaticon-tick"></i> On-Site & Remote Support</li>
                            <li><i class="flaticon-tick"></i> Fast Prototype Development</li>
                            <li><i class="flaticon-tick"></i> Extreme Durability & Performance Testing</li>
                            
                        </ul>
                        <h3>FAQ's</h3>
                        <div class="our-work-benefits">
                            <ul class="accordion">
                                <li class="accordion-item">
                                    <a class="accordion-title active" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Do you only develop rugged systems?
                                    </a>

                                    <p class="accordion-content show">No. We also work with rack-mounted, traditional computer systems and laptops. </p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Can you work with vehicle mounted systems?
                                    </a>

                                    <p class="accordion-content">Absolutely. We can build vehicle / trailer-mounted systems from scratch or we can update or modify your current systems. </p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Can I have multiple versions?
                                    </a>

                                    <p class="accordion-content">Yes. The one size fits all solution is not for everyone. We are happy to develop and maintain multiple versions as our goal is to ensure you are successful in every situation.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Do you provide maintenance or repair services?
                                    </a>

                                    <p class="accordion-content">Yes. Each system is unique and will require some type of maintenance and/or repairs over time. Depending on the complexity of the design, we can either train your operators to conduct their own maintenance or we can set up a maintenance schedule provided by our experts. Repairs or modifications will be handled on a case-by-case basis, but we always ensure to provide cost-effective solutions to get out-of-warranty systems back up and running. </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

               
            </div>
        </article>
    </div>
</section>
<!-- End Services Details Area -->
