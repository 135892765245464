import { Component, OnInit } from '@angular/core';
import { AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Lightbox } from 'ngx-lightbox';
import * as $ from 'jquery';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-gcs1',
  templateUrl: './gcs.component.html',
  styleUrls: ['./gcs.component.scss']
})


export class GCSComponent implements AfterViewInit {

  

  images = [
    {
      src: 'assets/img/Dual GCS/1.jpg',
      caption: 'Image 1',
      thumb: 'assets/img/Dual GCS/1.jpg'
    },
    {
      src: 'assets/img/Dual GCS/2.jpg',
      caption: 'Image 2',
      thumb: 'assets/img/Dual GCS/2.jpg'
    },
    {
      src: 'assets/img/Dual GCS/3.jpg',
      caption: 'Image 3',
      thumb: 'assets/img/Dual GCS/3.jpg'
    },
    {
      src: 'assets/img/Dual GCS/4.jpg',
      caption: 'Image 4',
      thumb: 'assets/img/Dual GCS/4.jpg'
    },
    {
      src: 'assets/img/Dual GCS/5.jpg',
      caption: 'Image 5',
      thumb: 'assets/img/Dual GCS/5.jpg'
    },
    {
      src: 'assets/img/Dual GCS/6.jpg',
      caption: 'Image 6',
      thumb: 'assets/img/Dual GCS/6.jpg'
    },
    {
      src: 'assets/img/Dual GCS/7.jpg',
      caption: 'Image 7',
      thumb: 'assets/img/Dual GCS/7.jpg'
    },
    {
      src: 'assets/img/Dual GCS/8.jpg',
      caption: 'Image 8',
      thumb: 'assets/img/Dual GCS/8.jpg'
    },
    {
      src: 'assets/img/Dual GCS/9.jpg',
      caption: 'SOFIC',
      thumb: 'assets/img/Dual GCS/9.jpg'
    },   
    {
      src: 'assets/img/Dual GCS/10.jpg',
      caption: 'Image 10',
      thumb: 'assets/img/Dual GCS/10.jpg'
    },   {
      src: 'assets/img/Dual GCS/11.jpg',
      caption: 'Image 11',
      thumb: 'assets/img/Dual GCS/11.jpg'
    },   {
      src: 'assets/img/Dual GCS/12.jpg',
      caption: 'Image 12',
      thumb: 'assets/img/Dual GCS/12.jpg'
    },
  ];

  constructor(private http: HttpClient, private lightbox: Lightbox) { }

  ngOnInit(): void {
  }

  

openLightbox(image: any) {
  const modal = document.getElementById('lightbox-modal');
  const imageElement = document.getElementById('lightbox-image');

  // Get the clicked image source and caption
  const src = image.src;
  const caption = image.caption;

  // Set the source and caption for the enlarged image
  imageElement.setAttribute('src', src);
  imageElement.setAttribute('alt', caption);

  // Show the modal
  modal.classList.add('uk-open');
}

ngAfterViewInit(): void {
  // your code here
}

}
