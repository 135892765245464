<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>Ruggedized Dual PC</h1>
        <ul>
            <li><a routerLink="/">Home</a></li>
            <li>Rugged-PC</li>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start Project Details Area -->
<section class="rugged-pc-area uk-rugged-pcs uk-section">
    <div class="uk-container">
        <div class="uk-child-width-1-2@m" uk-grid>
            <div>
        
             
        
                <div style="max-width: 600px;" class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1" uk-slideshow="min-width: 450; " >
        
                    <ul class="uk-slideshow-items">
                        
        
                        <li>
                            <img src="assets/img/GCSUpdated2.jpg" alt="" uk-cover>
                        </li>
        
                        <li>
                            <img src="assets/img/GCSUpdated3.jpg" alt="" uk-cover>
                        </li>
                        
                        <li>
                            <img src="assets/img/GCSUpdated4.jpg" alt="" uk-cover>
                        </li>
        
                        <li>
                            <img src="assets/img/GCSUpdated5.jpg" alt="" uk-cover>
                        </li>
        
                        <li>
                            <img src="assets/img/GCSUpdated6.jpg" alt="" uk-cover>
                        </li>
        
                        <li>
                            <img src="assets/img/GCSUpdated7.jpg" alt="" uk-cover>
                        </li>
        
                        <li>
                            <img src="assets/img/GCSUpdated8.jpg" alt="" uk-cover>
                        </li>

                        <li>
                            <img src="assets/img/GCSUpdated9.jpg" alt="" uk-cover>
                        </li>

                        <li>
                            <img src="assets/img/GCSUpdated10.jpg" alt="" uk-cover>
                        </li>

                        <li>
                            <img src="assets/img/GCSUpdated11.jpg" alt="" uk-cover>
                        </li>

                        <li>
                            <img src="assets/img/GCSUpdated12.jpg" alt="" uk-cover>
                        </li>

                        <li>
                            <img src="assets/img/GCSUpdated13.jpg" alt="" uk-cover>
                        </li>

                        <li>
                            <img src="assets/img/GCSUpdated14.jpg" alt="" uk-cover>
                        </li>
                    </ul>
        
                    <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
                    <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>
        
                </div>
        
            </div>
            
          

            <div class="min-width: 450; ">
                <div class="project-details-info">
                    <ul>
                        <li><Span>Customer Name:</Span> Multiple</li>
                        <li><Span>Category:</Span> Production, Continous Improvement</li>
                        <li><Span>Date:</Span> 03/2023</li>
                        <li><Span>Status:</Span> In Process</li>
                        <li><Span>Link:</Span> <a href="https://bit.ly/3EJEdqC">Dual Rugged PC Info Sheet</a></li>
                        <li><Span>Tags:</Span> <a>UAV</a>, <a>UAS</a>, <a>Dual PC</a>, <a>Windows</a>, <a>Linux</a>, <a>Custom Development</a></li>
                        <li>
                            <Span>Share our project:</Span>

                            <ul>
                               <!-- <li><a href="#"><i class="flaticon-logo"></i></a></li>
                                <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                                <li><a href="#"><i class="flaticon-logo-1"></i></a></li>-->
                                <li><a href="https://www.linkedin.com/sharing/share-offsite/?url=https://thirium.com/rugged-pc"><i class="flaticon-linkedin"></i></a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="rugged-pcs-desc">
            <h3>Ruggedized Dual PC Development</h3>
            <p>We are proud to announce that our latest UAS system made its debut at the 2022 Special Operations Forces Industry Conference (SOFIC). 
                This portable, rugged system is unique in that it combines two high-performance computers with separate operating systems (Windows and Linux) 
                to provide both ground control and payload capabilities for UAS systems.</p>

            
                <p>The Linux PC runs Edgybees, an AI-powered software that improves the accuracy of aerial video and satellite imagery on the fly. 
                    This user-friendly and efficient approach to implementing high-performance software eliminates the need for additional servers, 
                    making it an ideal solution for customers with similar needs. In fact, we are currently in the process of building a similar system for an 
                    international UAS customer.</p>
           

            
        </div>

        <div class="project-next-and-prev">
            <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-2@s">
                <div class="item">
                    <a href="future-projects" class="uk-button uk-button-default">Prev Project</a>
                </div>

                <div class="item uk-text-right">
                    <a href="gcs" class="uk-button uk-button-default">Next Project</a>
                </div>
            </div>
        </div>
    </div>

    
    
</section>
<!-- End Project Details Area -->

