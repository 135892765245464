<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>Training</h1>
        <ul>
            <li><a routerLink="/">Home</a></li>
            <li>Services Details</li>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area uk-services-details uk-section">
    <div class="uk-container">
        <article class="uk-article services-details">
            <div class="uk-grid uk-flex">

                <div class="uk-sidebar uk-width-1-5 uk-flex-first@l uk-first-column">
                    <!-- <div class="widget widget_search">
                         <form>
                             <input type="text" class="uk-input" placeholder="Search here...">
                             <button type="submit"><i class="flaticon-search"></i></button>
                         </form>
                     </div>-->
 <app-servicesmenu></app-servicesmenu>
                   
                     <div class="widget widget_download">
                         <!--<h3 class="widget-title">Download</h3>
                         <div class="bar"></div>-->
 
                         <ul>
                             <!--<li><a href="https://www.dropbox.com/s/93jd2v7hy64ii8f/Advanced%20Computers%20Logo.png?dl=0">Capabilities Brief <i class="flaticon-edit"></i></a></li>-->
                             <!--<li><a href="#">Our Iso Certificate <i class="flaticon-edit"></i></a></li>
                             <li><a href="#">Great Technology <i class="flaticon-edit"></i></a></li>-->
                         </ul>
                     </div>
                 </div>
                <div class="inner uk-width-expand">
                    <div class="services-details-desc">
                        <h3>Training</h3>
                        

                        <p>Training programs can be a significant liability for businesses, especially when courses are not run consistently. 
                            This can leave you with two options: either hire and maintain a full-time training staff, or have existing team members 
                            take on the role of instructors as an additional duty. Either way, the overhead costs can be high, and the quality of training may suffer.</p>

                            <p>At Thirium, we offer a solution to this problem by providing top-quality training services that can help you eliminate overhead 
                                costs while maintaining a high level of expertise. Our team of experienced instructors can provide your staff with the knowledge 
                                and skills they need to succeed, without the burden of maintaining a full-time training department.</p>

                        <div class="services-image-slides owl-carousel owl-theme">
                            <div class="item">
                                <img src="assets/img/Thirium Vulture/4.jpg" alt="img">
                            </div>

                           <!-- <div class="item">
                                <img src="assets/img/blog-details.jpg" alt="img">
                            </div>

                            <div class="item">
                                <img src="assets/img/blog-details.jpg" alt="img">
                            </div>-->
                        </div>
                        <h3>Thirium Training Benefits</h3>
                        <p>Our instructors are some of the best and most experienced in the world.</p>

                        <ul class="services-features-list">
                            <li><i class="flaticon-tick"></i> AVO, MECH & Operational Training</li>
                            <li><i class="flaticon-tick"></i> We Build the Training Program</li>
                            <li><i class="flaticon-tick"></i> No Costs Unless Training is Occurring</li>
                            <li><i class="flaticon-tick"></i> You Own the Training Program </li>
                            <li><i class="flaticon-tick"></i> No Airspace Requests. We Handle It</li>
                            <li><i class="flaticon-tick"></i> Low-Risk Flight Training</li>
                        </ul>

                        <!--<blockquote class="blockquote">
                            <p>Sed ut perspiciatis undeomnis iste natus error sit voluptatem accusantium dolore Totam rem aperiam with a long list of products and never ending customer support.</p>
                        </blockquote>-->

                        <br>
                        <h3>FAQ's</h3>
                        <div class="our-work-benefits">
                            <ul class="accordion">
                                <li class="accordion-item">
                                    <a class="accordion-title active" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        What do you mean we own the training program?
                                    </a>

                                    <p class="accordion-content show">At some point, you'll likely be in a position where you want or it makes financial sense to run your own program.
                                         When that time comes, the program we developed is yours and we'll work with you to migrate everything over ensuring a smooth transition.
                                         </p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        We want to run our own training program, but would you be willing to build it?
                                    </a>

                                    <p class="accordion-content">Absolutely. Building programs is one of our favorite things to do. </p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Can you train our instructors?
                                    </a>

                                    <p class="accordion-content">Yes. We offer a variety of options and instructor courses are one of them.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Do you need to keep one of our aircraft for this to work?
                                    </a>

                                    <p class="accordion-content">It's ideal for us to maintain one or more of your aircraft. This allows our team to stay current on all systems and train new Thirium employees. However, we realize this isn't always possible and are open to discussing solutions. </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                
            </div>
        </article>
    </div>
</section>
<!-- End Services Details Area -->

