<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>UAS Integrations</h1>
        <ul>
            <li><a routerLink="/">Home</a></li>
            <li>Services Details</li>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area uk-services-details uk-section">
    <div class="uk-container">
        <article class="uk-article services-details">
            <div class="uk-grid uk-flex">

                <div class="uk-sidebar uk-width-1-5 uk-flex-first@l uk-first-column">
                    <app-servicesmenu></app-servicesmenu>
                    <div class="widget widget_download"></div>
                </div>

                <div class="inner uk-width-expand">
                    <div class="services-details-desc">
                        <h3>UAS Integration Expertise</h3>
                        <p>At Thirium, we specialize in the seamless integration of autopilots, communication systems, cameras, EW payloads, and more into UAS platforms. Our holistic approach ensures that every component works in harmony, maximizing the potential of your UAS operations.</p>
                        <p>Our end-to-end services include comprehensive flight testing, detailed documentation, and manual updates. We hand over a truly finished product, primed for field operations. And if required, we also provide training, ensuring your team can harness the full power of the integrated systems.</p>

                        <div class="services-image-slides owl-carousel owl-theme">
                            <div class="item">
                                <img src="assets/img/Thirium Box/thirium_box.jpg" alt="UAS Integration Image">
                            </div>
                        </div>

                        <h3>Why Choose Thirium for UAS Integrations?</h3>
                        <ul class="services-features-list">
                            <li><i class="flaticon-tick"></i> Expertise in diverse UAS components</li>
                            <li><i class="flaticon-tick"></i> Comprehensive flight testing</li>
                            <li><i class="flaticon-tick"></i> Detailed documentation and manual updates</li>
                            <li><i class="flaticon-tick"></i> Training for seamless onboarding</li>
                        </ul>

                        <!-- <h3>FAQ's</h3>
                        <div class="our-work-benefits"> -->
                            <!-- [Your FAQ Accordion Goes Here] -->
                        <!-- </div> -->
                    </div>
                </div>
            </div>
        </article>
    </div>
</section>
<!-- End Services Details Area -->
