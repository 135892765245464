<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>Standardization</h1>
        <ul>
            <li><a routerLink="/">Home</a></li>
            <li>Services Details</li>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area uk-services-details uk-section">
    <div class="uk-container">
        <article class="uk-article services-details">
            <div class="uk-grid uk-flex">

                <div class="uk-sidebar uk-width-1-5 uk-flex-first@l uk-first-column">
                    <!-- <div class="widget widget_search">
                         <form>
                             <input type="text" class="uk-input" placeholder="Search here...">
                             <button type="submit"><i class="flaticon-search"></i></button>
                         </form>
                     </div>-->
 
                   <app-servicesmenu></app-servicesmenu>
 
                     <div class="widget widget_download">
                         <!--<h3 class="widget-title">Download</h3>
                         <div class="bar"></div>-->
 
                         <ul>
                             <!--<li><a href="https://www.dropbox.com/s/93jd2v7hy64ii8f/Advanced%20Computers%20Logo.png?dl=0">Capabilities Brief <i class="flaticon-edit"></i></a></li>-->
                             <!--<li><a href="#">Our Iso Certificate <i class="flaticon-edit"></i></a></li>
                             <li><a href="#">Great Technology <i class="flaticon-edit"></i></a></li>-->
                         </ul>
                     </div>
                 </div>
                 
                <div class="inner uk-width-expand">
                    <div class="services-details-desc">
                        <h3>Standardization & Evaluations</h3>
                        

                        <p>Maintaining a successful UAS program requires a well-trained, competent workforce that operates according to established standards and protocols. 
                            This helps to minimize mistakes, reduce risk, and ensure that tasks are performed efficiently and effectively.</p>

                            <p>At Thirium, we understand the importance of effective training and evaluation programs for UAS operators and mechanics. 
                                These programs not only help to ensure that employees are performing tasks correctly, but they also provide valuable insights into the skill 
                                level of each individual. This information can be used to make informed management decisions and optimize the 
                                performance of your UAS program.</p>
                               

                        <div class="services-image-slides owl-carousel owl-theme">
                            <div class="item">
                                <img src="assets/img/standard.jpg" alt="img">
                            </div>

                           <!-- <div class="item">
                                <img src="assets/img/blog-details.jpg" alt="img">
                            </div>

                            <div class="item">
                                <img src="assets/img/blog-details.jpg" alt="img">
                            </div>-->
                        </div>
                        <h3>Thirium Standardization System</h3>
                        <p>We build systems that provide real value to both the employee and the company.</p>

                        <ul class="services-features-list">
                            <li><i class="flaticon-tick"></i> Operational Competencies</li>
                            <li><i class="flaticon-tick"></i> Technical Competencies</li>
                            <li><i class="flaticon-tick"></i> Attitude, Work Ethic, Care</li>
                            <li><i class="flaticon-tick"></i> Annual Evaluations</li>
                            <li><i class="flaticon-tick"></i> High-Level Decision Making Tool</li>
                            <li><i class="flaticon-tick"></i> Skill Level Series Tasks</li>
                        </ul>

                        <!--<blockquote class="blockquote">
                            <p>Sed ut perspiciatis undeomnis iste natus error sit voluptatem accusantium dolore Totam rem aperiam with a long list of products and never ending customer support.</p>
                        </blockquote>-->

                        <br>
                            
                        
                        <h3>FAQ's</h3>
                        <div class="our-work-benefits">
                            <ul class="accordion">
                                <li class="accordion-item">
                                    <a class="accordion-title active" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Do you have template systems you can license or sell to us?
                                    </a>

                                    <p class="accordion-content show">Possibly. Please contact us to discuss. </p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        When should we consider implementing this type of system?
                                    </a>

                                    <p class="accordion-content">It's recommended to have a standardization and evaluation system in place prior to selling a system or service to any customer.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Are these systems difficult to maintain?
                                    </a>

                                    <p class="accordion-content">They can be. However, with the right processes in place, they are fairly easy.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Whats the real benefit here?
                                    </a>

                                    <p class="accordion-content">These systems lower risk, reduce user error incidents, combats complacency, and ensure you have the right people in the right positions. The benefits are enormous. </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>-->

                
            </div>
        </article>
    </div>
</section>
<!-- End Services Details Area -->

