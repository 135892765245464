<div class="uk-container uk-container-center uk-margin-large-top uk-margin-large-bottom">
    <div class="uk-grid-large uk-child-width-expand@m" uk-grid>
        <div class="uk-width-1-2@m uk-width-1-1@s uk-align-center">
            <div class="uk-card uk-card-default uk-card-body">
                <h1 class="uk-card-title">Access Restricted</h1>
                <p>Due to the sensitive nature of our industry, we have implemented strict security measures. These measures may restrict access due to the following:</p>
                <ul class="uk-list uk-list-bullet">
                    <li>Specific locations that are identified as potential risks</li>
                    <li>Certain IP addresses flagged in our system</li>
                    <li>Instances where location services are hidden or disabled</li>
                </ul>
                <p>If you're using location-hiding tools for privacy, please reload this page with location services enabled.</p>
                <p>If you believe you have encountered this restriction in error, we apologize for any inconvenience caused. Please contact our team through the form below, and we'll review your case as quickly as possible. Once verified, we may whitelist your location or IP.</p>
                <!-- Contact Form Goes Here -->
            </div>
        </div>
    </div>
</div>


<!-- Start Feedback Area -->
<section id="clients" class="feedback-area uk-dark uk-section uk-feedback">
    <div class="uk-container">
      <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
        <div class="item">
          <div class="feedback-img">
            <img src="assets/img/brandtest.png" alt="image">
          </div>
        </div>
        <div class="item">
          <div class="uk-section-title section-title">
            <span>Let's Talk</span>
            <h2>Get in Touch</h2>
          </div>
  
          <form #contactForm id="contactForm" action="https://formspree.io/f/mvonjnvr" method="POST">
            <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
              <div class="item uk-margin">
                <input type="text" class="uk-input" name="name" id="name" placeholder="Name">
              </div>
              <div class="item uk-margin">
                <input type="email" class="uk-input" name="email" id="email" placeholder="Email">
              </div>
              <div class="item uk-margin">
                <input type="text" class="uk-input" placeholder="Phone">
              </div>
              <div class="item uk-margin">
                <input type="text" class="uk-input" name="subject" id="subject" placeholder="Subject">
              </div>
            </div>
            <div class="item">
              <textarea name="message" class="uk-textarea" id="message" cols="30" rows="4" placeholder="Your Message"></textarea>
            </div>
            <input type="hidden" name="_replyto" value="alawson@thirium.com">
            <button type="submit" class="uk-button uk-button-default">Submit Message</button>
          </form>
        </div>
      </div>
    </div>
  </section>
<!-- End Feedback Area -->
