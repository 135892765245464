<!-- Start Services Area -->
<section id="services" class="services-area uk-services uk-section uk-dark">
    <div class="uk-container">
        <div class="uk-section-title section-title">
            <span>Thirium, Inc</span>
            <h2>Products & Services</h2>
            <div class="bar"></div>

            <!-- <a routerLink="/services" class="uk-button uk-button-default">All Services</a> -->
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <img src="assets/icons/drone.png" alt="Drone Icon" class="icon-img">
                    </div>
                    
                    <h3>Unmanned Aerial Systems (UAS)</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/vulture" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <img src="assets/icons/payload.png" alt="Drone Icon" class="icon-img">
                    </div>
                    <h3>UAS Payloads</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/uastech" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <img src="assets/icons/GCS.png" alt="Drone Icon" class="icon-img">
                    </div>
                    <h3>Ground Control Stations</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/gcs" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <img src="assets/icons/software.png" alt="Drone Icon" class="icon-img">
                    </div>
                    <h3>Software Solutions</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/software-development" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services active">
                    <div class="icon">
                        <img src="assets/icons/engineering.png" alt="Drone Icon" class="icon-img">
                    </div>
                    <h3>Engineering Services</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/integrations" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <img src="assets/icons/pilot.png" alt="Drone Icon" class="icon-img">
                    </div>
                    <h3>Pilot & Flight Services</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/uaspilotservices" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <img src="assets/icons/training.png" alt="Drone Icon" class="icon-img">
                    </div>
                    <h3>Training & Support Services</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/training" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <img src="assets/icons/consulting.png" alt="Drone Icon" class="icon-img">
                    </div>
                    <h3>Consulting & Evaluations</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/consulting" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <img src="assets/icons/ops.png" alt="Drone Icon" class="icon-img">
                    </div>
                    <h3>Operational Support & Services</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/standardization" class="link uk-position-cover"></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->