<!-- Start Careers Area -->
<section id="careers" class="uk-dark uk-section">
    <div class="uk-container">
        <div class="uk-section-title section-title">
            <span>Join Our Team</span>
            <h2>Careers at Thirium, Inc</h2>
            <p>At Thirium, we're always looking for talented individuals who are passionate about the UAS industry. We are currently exploring potential roles for future growth and expansion.</p>
        </div>
        
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s uk-grid-row-small">
      <!-- Job Listing 1 -->
<!-- Job Listing 1 -->
<div class="item uk-margin">
    <div class="uk-card uk-card-secondary uk-card-body">
        <h3 class="uk-card-title">Chief Technology Officer</h3>
        <p>We are in the exploratory phase of hiring for a future CTO role, as we prepare for growth and expansion.</p>
        <button class="uk-button uk-button-default" uk-toggle="target: #modal-cto">Learn More</button>
    </div>

    <!-- Start Modal for CTO -->
    <div id="modal-cto" uk-modal>
        <div class="uk-modal-dialog uk-modal-body">
            <h2 class="uk-modal-title">CTO (Exploratory Phase)</h2>
            <p>
                As the Chief Technology Officer (CTO) at Thirium, you will play a crucial role in shaping our technological vision and driving innovation in the UAS industry. Your primary responsibility will be to lead the development and implementation of our technology strategy, ensuring that it aligns with our business objectives and positions us as a leader in the market.
            </p>
            
            <p><strong>Responsibilities:</strong></p>
            <ul>
                <li>Provide strategic guidance and leadership on all technology-related matters.</li>
                <li>Collaborate with cross-functional teams to define and execute technology initiatives.</li>
                <li>Oversee the development and maintenance of our software systems and infrastructure.</li>
                <li>Identify and evaluate emerging technologies to drive innovation and improve efficiency.</li>
                <li>Manage a team of skilled engineers and developers, fostering a culture of continuous learning and growth.</li>
                <li>Stay up-to-date with industry trends and best practices to ensure our technology remains cutting-edge.</li>
            </ul>

            <p><strong>Qualifications:</strong></p>
            <ul>
                <li>Bachelor's or Master's degree in Computer Science, Engineering, or a related field.</li>
                <li>Proven experience in a senior technology leadership role, preferably within the UAS industry.</li>
                <li>Strong knowledge of software development methodologies and technologies.</li>
                <li>Excellent strategic thinking and problem-solving skills.</li>
                <li>Strong leadership and communication abilities.</li>
            </ul>

            <p>
                If you are passionate about technology and have a drive for innovation, we would love to hear from you.
            </p>

            <p>
                To apply for this position, please submit your resume using the form below.
            </p>

            <form #ctoForm="ngForm" (ngSubmit)="submitForm(ctoForm, 'cto', ctoApplication)" enctype="multipart/form-data" id="ctoForm">
                <div class="uk-margin">
                  <label class="uk-form-label" for="cto-email">Your Email:</label>
                  <div class="uk-form-controls">
                    <input class="uk-input uk-form-width-large" id="cto-email" type="email" name="email" [(ngModel)]="ctoApplication.email" style="background-color: #f5f5f5;">
                  </div>
                </div>
                <div class="uk-margin">
                  <label class="uk-form-label" for="cto-resume">Your Resume:</label>
                  <div class="uk-form-controls">
                    <input class="uk-input uk-form-width-large" id="cto-resume" type="file" name="resume" (change)="onFileChange($event, ctoApplication)">
                  </div>
                </div>
                <div class="uk-flex uk-flex-right">
                    <button class="uk-button uk-button-default uk-modal-close" type="button">Close</button>
                    <button class="uk-button uk-button-default" type="submit">Apply Now</button>
                </div>
            </form>
            
            
            
        </div>
    </div>
    <!-- End Modal for CTO -->
</div>






    <!-- Job Listing 2 - Director of Business Development -->
<div class="item">
    <div class="uk-card uk-card-secondary uk-card-body">
      <h3 class="uk-card-title">Director of Business Development </h3>
      <p>We are in the exploratory phase of hiring for a future Director of Business Development, as we prepare for growth and expansion.</p>
      <button class="uk-button uk-button-default" uk-toggle="target: #modal-director-bd">Learn More</button>
    </div>
  
    <!-- Start Modal for Director of Business Development -->
    <div id="modal-director-bd" uk-modal>
      <div class="uk-modal-dialog uk-modal-body">
        <h2 class="uk-modal-title">Director of Business Development (Exploratory Phase)</h2>
        <p>
          As the Director of Business Development at Thirium, you will play a key role in driving the growth and success of our company in the UAS industry. Your primary responsibility will be to identify and pursue new business opportunities, develop strategic partnerships, and expand our customer base.
        </p>
  
        <p><strong>Responsibilities:</strong></p>
        <ul>
          <li>Lead the development and execution of the business development strategy.</li>
          <li>Identify and evaluate potential business opportunities and partnerships.</li>
          <li>Negotiate and close deals with clients and partners.</li>
          <li>Build and maintain relationships with key stakeholders.</li>
          <li>Collaborate with internal teams to ensure effective delivery of products and services.</li>
          <li>Stay up-to-date with industry trends and market dynamics.</li>
        </ul>
  
        <p><strong>Qualifications:</strong></p>
        <ul>
          <li>Bachelor's or Master's degree in Business Administration, Marketing, or a related field.</li>
          <li>Proven experience in business development or sales roles, preferably within the UAS industry.</li>
          <li>Excellent communication and negotiation skills.</li>
          <li>Strong analytical and strategic thinking abilities.</li>
          <li>Ability to work effectively in a fast-paced and dynamic environment.</li>
        </ul>
  
        <p>
          If you are driven, entrepreneurial, and passionate about business development, we would love to hear from you.
        </p>
  
        <p>
          To apply for this position, please submit your resume using the form below.
        </p>
  
        <form #directorBdForm="ngForm" (ngSubmit)="submitForm(directorBdForm, 'director-bd', directorBdApplication)" enctype="multipart/form-data" id="directorBdForm">
            <div class="uk-margin">
              <label class="uk-form-label" for="directorBdEmail">Your Email:</label>
              <div class="uk-form-controls">
                <input class="uk-input uk-form-width-large" id="directorBdEmail" type="email" name="email" [(ngModel)]="directorBdApplication.email" style="background-color: #f5f5f5;">
              </div>
            </div>
            <div class="uk-margin">
              <label class="uk-form-label" for="directorBdResume">Your Resume:</label>
              <div class="uk-form-controls">
                <input class="uk-input uk-form-width-large" id="directorBdResume" type="file" name="resume" (change)="onFileChange($event, directorBdApplication)">
              </div>
            </div>
            <div class="uk-flex uk-flex-right">
              <button class="uk-button uk-button-default uk-modal-close" type="button">Close</button>
              <button class="uk-button uk-button-default" type="submit">Apply Now</button>
            </div>
          </form>
          
      </div>
    </div>
    <!-- End Modal for Director of Business Development -->
  </div>
  

<!-- Job Listing 3 - UAS Pilots with Piccolo Experience -->
<div class="item">
    <div class="uk-card uk-card-secondary uk-card-body">
      <h3 class="uk-card-title">UAS Pilots with Piccolo Experience (Contract)</h3>
      <p>We are looking for experienced UAS pilots with Piccolo Autopilot experience for potential contract roles.</p>
      <button class="uk-button uk-button-default" uk-toggle="target: #modal-pilot-piccolo">Learn More</button>
    </div>
  
    <!-- Start Modal for UAS Pilots with Piccolo Experience -->
    <div id="modal-pilot-piccolo" uk-modal>
      <div class="uk-modal-dialog uk-modal-body">
        <h2 class="uk-modal-title">UAS Pilots with Piccolo Experience (Contract)</h2>
        <p>
          As a UAS Pilot with Piccolo Experience, you will be responsible for operating group 2/3 unmanned aircraft systems (UAS) equipped with various group Piccolo Autopilots. You will support various projects and missions, providing expertise in UAS operations and ensuring safe and efficient flights.
        </p>
  
        <p><strong>Responsibilities:</strong></p>
        <ul>
          <li>Conduct pre-flight checks and ensure compliance with regulations and safety protocols.</li>
          <li>Operate UAS with precision and accuracy to capture data and perform required tasks.</li>
          <li>Monitor and maintain UAS equipment and troubleshoot any issues that arise.</li>
          <li>Collaborate with the team to plan and execute UAS missions effectively.</li>
          <li>Document and report flight activities and results.</li>
          <li>Adhere to project timelines and deliver high-quality results.</li>
        </ul>
  
        <p><strong>Qualifications:</strong></p>
        <ul>
          <li>Recent deployment experience is a plus.</li>
          <li>Extensive experience operating UAS, specifically with Piccolo Autopilot.</li>
          <li>Familiarity with UAS flight planning and mission execution.</li>
          <li>Strong knowledge of airspace regulations and safety procedures.</li>
          <li>Excellent problem-solving, decision-making and troubleshooting skills.</li>
          <li>Ability to work effectively in a team and communicate clearly.</li>
        </ul>
  
        <p>
          If you have the required experience and are passionate about UAS operations, we would love to hear from you.
        </p>
  
        <p>
          To apply for this position, please submit your resume using the form below.
        </p>
  
        <form #pilotPiccoloForm="ngForm" (ngSubmit)="submitForm(pilotPiccoloForm, 'pilot-piccolo', pilotPiccoloApplication)" enctype="multipart/form-data">
            <div class="uk-margin">
              <label class="uk-form-label" for="pilotPiccoloEmail">Your Email:</label>
              <div class="uk-form-controls">
                <input class="uk-input uk-form-width-large" id="pilotPiccoloEmail" type="email" name="email" [(ngModel)]="pilotPiccoloApplication.email" style="background-color: #f5f5f5;">
              </div>
            </div>
            <div class="uk-margin">
              <label class="uk-form-label" for="pilotPiccoloResume">Your Resume:</label>
              <div class="uk-form-controls">
                <input class="uk-input uk-form-width-large" id="pilotPiccoloResume" type="file" name="resume" (change)="onFileChange($event, pilotPiccoloApplication)">
              </div>
            </div>
          <div class="uk-flex uk-flex-right">
            <button class="uk-button uk-button-default uk-modal-close" type="button">Close</button>
            <button class="uk-button uk-button-default" type="submit">Apply Now</button>
          </div>
        </form>
      </div>
    </div>
    <!-- End Modal for UAS Pilots with Piccolo Experience -->
  </div>
  

       <!-- Job Listing 4 - Part 107 Drone Pilots -->
<div class="item uk-margin">
    <div class="uk-card uk-card-secondary uk-card-body">
      <h3 class="uk-card-title">Part 107 Drone Pilots (Contract)</h3>
      <p>We are looking for certified Part 107 Drone Pilots for potential contract roles.</p>
      <button class="uk-button uk-button-default" uk-toggle="target: #modal-drone-pilot">Learn More</button>
    </div>
  
    <!-- Start Modal for Part 107 Drone Pilots -->
    <div id="modal-drone-pilot" uk-modal>
      <div class="uk-modal-dialog uk-modal-body">
        <h2 class="uk-modal-title">Part 107 Drone Pilots (Contract)</h2>
        <p>
          As a Part 107 Drone Pilot, you will be responsible for operating small unmanned aerial vehicles (UAVs) under the guidelines and regulations set forth by the Federal Aviation Administration (FAA). Your expertise in drone operations will be crucial in supporting various projects and missions.
        </p>
  
        <p><strong>Responsibilities:</strong></p>
        <ul>
          <li>Conduct pre-flight checks and ensure compliance with Part 107 regulations.</li>
          <li>Operate drones with precision and accuracy to capture data and perform required tasks.</li>
          <li>Maintain a thorough understanding of airspace restrictions and safety protocols.</li>
          <li>Collaborate with the team to plan and execute drone missions effectively.</li>
          <li>Document and report flight activities and results.</li>
          <li>Adhere to project timelines and deliver high-quality results.</li>
        </ul>
  
        <p><strong>Qualifications:</strong></p>
        <ul>
          <li>Hold a valid FAA Part 107 Remote Pilot Certificate.</li>
          <li>Demonstrate proficiency in operating drones and handling various flight scenarios.</li>
          <li>Knowledge of airspace regulations and safety procedures.</li>
          <li>Experience in capturing aerial imagery or data is preferred.</li>
          <li>Excellent problem-solving and decision-making skills.</li>
          <li>Ability to work effectively in a team and communicate clearly.</li>
        </ul>
  
        <p>
          If you are a certified Part 107 Drone Pilot and are passionate about leveraging drone technology, we would love to hear from you.
        </p>
  
        <p>
          To apply for this position, please submit your resume using the form below.
        </p>
  
        <form #dronePilotForm="ngForm" (ngSubmit)="submitForm(dronePilotForm, 'drone-pilot', dronePilotApplication)" enctype="multipart/form-data">
            <div class="uk-margin">
              <label class="uk-form-label" for="dronePilotEmail">Your Email:</label>
              <div class="uk-form-controls">
                <input class="uk-input uk-form-width-large" id="dronePilotEmail" type="email" name="email" [(ngModel)]="dronePilotApplication.email" style="background-color: #f5f5f5;">
              </div>
            </div>
            <div class="uk-margin">
              <label class="uk-form-label" for="dronePilotResume">Your Resume:</label>
              <div class="uk-form-controls">
                <input class="uk-input uk-form-width-large" id="dronePilotResume" type="file" name="resume" (change)="onFileChange($event, dronePilotApplication)">
              </div>
            </div>
          <div class="uk-flex uk-flex-right">
            <button class="uk-button uk-button-default uk-modal-close" type="button">Close</button>
            <button class="uk-button uk-button-default" type="submit">Apply Now</button>
          </div>
        </form>
      </div>
    </div>
    <!-- End Modal for Part 107 Drone Pilots -->
  </div>
  

           <!-- Job Listing 5 - Group 3 UAS Mechanic with VTOL Experience -->
<div class="item uk-margin">
    <div class="uk-card uk-card-secondary uk-card-body">
      <h3 class="uk-card-title">Group 3 UAS Mechanic with VTOL Experience (Contract)</h3>
      <p>We are looking for experienced UAS mechanics with Group 3 VTOL experience for potential contract roles.</p>
      <button class="uk-button uk-button-default" uk-toggle="target: #modal-mechanic-vtol">Learn More</button>
    </div>
  
    <!-- Start Modal for Group 3 UAS Mechanic with VTOL Experience -->
    <div id="modal-mechanic-vtol" uk-modal>
      <div class="uk-modal-dialog uk-modal-body">
        <h2 class="uk-modal-title">Group 3 UAS Mechanic with VTOL Experience (Contract)</h2>
        <p>
          As a Group 3 UAS Mechanic with VTOL Experience, you will be responsible for maintaining and servicing unmanned aerial systems (UAS) with both VTOL and non-VTOL capabilities. Your expertise in UAS systems, pre/post-flight checklists, troubleshooting, repairs, and modifications will be crucial in ensuring the reliability and performance of our fleet.
        </p>
  
        <p><strong>Responsibilities:</strong></p>
        <ul>
          <li>Perform routine inspections, maintenance, and repairs on Group 2 and Group 3 UAS with and without VTOL capabilities.</li>
          <li>Troubleshoot and diagnose mechanical issues to identify and implement effective solutions.</li>
          <li>Conduct pre-flight checks and ensure airworthiness of UAS before missions.</li>
          <li>Collaborate with the operations team to coordinate maintenance schedules and optimize fleet availability.</li>
          <li>Maintain accurate records of maintenance activities and ensure compliance with regulatory requirements.</li>
          <li>Stay updated with industry trends and advancements in UAS mechanics and VTOL technology.</li>
        </ul>
  
        <p><strong>Qualifications:</strong></p>
        <ul>
          <li>Proven experience as a UAS mechanic, preferably with Group 2 and Group 3 UAS and VTOL systems.</li>
          <li>Strong knowledge of UAS components, systems, pre/post-flight checklists, and maintenance procedures.</li>
          <li>Experience in troubleshooting and diagnosing mechanical issues.</li>
          <li>Familiarity with UAS regulations and safety protocols.</li>
          <li>Excellent problem-solving and decision-making skills.</li>
          <li>Ability to work effectively in a team and communicate clearly.</li>
        </ul>
  
        <p>
          If you have the required experience in UAS mechanics and VTOL systems and are passionate about supporting the operation of cutting-edge UAS technology, we would love to hear from you.
        </p>
  
        <p>
          To apply for this position, please submit your resume using the form below.
        </p>
  
        <form #mechanicVtolForm="ngForm" (ngSubmit)="submitForm(mechanicVtolForm, 'mechanic-vtol', mechanicVtolApplication)" enctype="multipart/form-data">
            <div class="uk-margin">
              <label class="uk-form-label" for="mechanicVtolEmail">Your Email:</label>
              <div class="uk-form-controls">
                <input class="uk-input uk-form-width-large" id="mechanicVtolEmail" type="email" name="email" [(ngModel)]="mechanicVtolApplication.email" style="background-color: #f5f5f5;">
              </div>
            </div>
            <div class="uk-margin">
              <label class="uk-form-label" for="mechanicVtolResume">Your Resume:</label>
              <div class="uk-form-controls">
                <input class="uk-input uk-form-width-large" id="mechanicVtolResume" type="file" name="resume" (change)="onFileChange($event, mechanicVtolApplication)">
              </div>
            </div>
          <div class="uk-flex uk-flex-right">
            <button class="uk-button uk-button-default uk-modal-close" type="button">Close</button>
            <button class="uk-button uk-button-default" type="submit">Apply Now</button>
          </div>
        </form>
      </div>
    </div>
    <!-- End Modal for Group 3 UAS Mechanic with VTOL Experience -->
  </div>
  
  

        </div>
    </div>
</section>
<!-- End Careers Area -->

  

<!-- Benefits Section -->
<section class="uk-section uk-section-secondary uk-light">
    <div class="uk-container">
        <h2 class="uk-heading-bullet uk-text-center"><span>Benefits of Working at Thirium, Inc.</span></h2>
        <div class="uk-grid-large uk-child-width-1-3@m uk-text-center" uk-grid>
            <div>
                <div class="uk-card uk-card-hover uk-card-body uk-light uk-transition-toggle" #ptoCard tabindex="0" title="Experience the freedom of managing your own time. Take as many days off as you need to balance work with life." uk-tooltip="pos: top">
                    <div class="my-custom-tooltip">
                        <span uk-icon="icon: calendar; ratio: 2"></span>
                        <h3 class="uk-card-title">Unlimited PTO</h3>
                    </div>
                </div>
            </div>
            <!-- Add tooltip and class to the rest of the benefit cards -->
            <div>
                <div class="uk-card uk-card-hover uk-card-body uk-light uk-transition-toggle" #medicalCard tabindex="0" title="Health is wealth. At Thirium, we cover 100% of employee medical insurance premiums on our silver plans to keep you healthy." uk-tooltip="pos: top">
                    <div class="my-custom-tooltip">
                        <span uk-icon="icon: heart; ratio: 2"></span>
                        <h3 class="uk-card-title">100% Company Paid Medical</h3>
                    </div>
                </div>
            </div>
            <div>
                <div class="uk-card uk-card-hover uk-card-body uk-light uk-transition-toggle" #visionCard tabindex="0" title="Beyond medical, we also offer vision and dental plans to provide comprehensive care to our employees. We also cover 75% of the cost of these!" uk-tooltip="pos: top">
                    <div class="my-custom-tooltip">
                        <span uk-icon="icon: happy; ratio: 2"></span>
                        <h3 class="uk-card-title">Vision & Dental</h3>
                    </div>
                </div>
            </div>
            <div>
                <div class="uk-card uk-card-hover uk-card-body uk-light uk-transition-toggle" #stockCard tabindex="0" title="Become a partner, not just an employee. Our stock options program lets you participate in the company's growth and success. Avilable for a limited time and limtied to certain positions." uk-tooltip="pos: top">
                    <div class="my-custom-tooltip">
                        <span uk-icon="icon: bolt; ratio: 2"></span>
                        <h3 class="uk-card-title">Stock Options</h3>
                    </div>
                </div>
            </div>
            <div>
                <div class="uk-card uk-card-hover uk-card-body uk-light uk-transition-toggle" #developmentCard tabindex="0" title="We provide opportunities for cross-training, learning new skills, and offer ample opportunities for remote and in-person training courses. The more you know, the better we are." uk-tooltip="pos: top">
                    <div class="my-custom-tooltip">
                        <span uk-icon="icon: star; ratio: 2"></span>
                        <h3 class="uk-card-title">Continuous Professional Development</h3>
                    </div>
                </div>
            </div>
            <div>
                <div class="uk-card uk-card-hover uk-card-body uk-light uk-transition-toggle" #environmentCard tabindex="0" title="Thrive in a stimulating work environment that encourages creativity, collaboration, and growth." uk-tooltip="pos: top">
                    <div class="my-custom-tooltip">
                        <span uk-icon="icon: cog; ratio: 2"></span>
                        <h3 class="uk-card-title">Dynamic Work Environment</h3>
                    </div>
                </div>
            </div>
            
            
            
        </div>
    </div>
</section>

<!-- Culture Section -->
<section class="uk-section uk-dark uk-background-cover" style="background-image: url('assets/img/dark-bg.jpg');">
    <div class="uk-container">
        <h2 class="uk-heading-bullet uk-text-center"><span style="color: #1e87f0;">Our Culture</span></h2>
        <div class="uk-grid uk-child-width-1-2@m uk-flex-middle" uk-grid>
            <div>
                <div class="uk-card uk-card-body uk-box-shadow-xlarge uk-light">
                    <img src="assets/img/beard-man.png" alt="Culture Image" uk-img>
                </div>
            </div>
            <div class="uk-card uk-card-default uk-card-body uk-light uk-background-secondary">
                <p class="uk-text-lead">At Thirium, we're not just recruiting professionals - we're cultivating champions.</p>
                <p class="uk-text">Our environment thrives on challenge and champions excellence. We uphold a culture that:</p>
                <ul class="uk-list uk-list-disc uk-text uk-text-white">
                    <li style="color: white;">Embraces commitment</li>
                    <li style="color: white;">Demands high performance</li>
                    <li style="color: white;">Leaves no room for complacency</li>
                </ul>
                <p class="uk-text">Our sights are set on supporting Tier 1 Operations, a goal we strive towards each day with unwavering resolve. Joining Thirium means becoming part of an ambitious journey that rewards your dedication and sparks your potential.</p>
                <p class="uk-text">We stand united in our mission, unyielding in the face of indifference. Step into a future where passion propels us forward, and excellence marks our achievements. This is Thirium.</p>
            </div>
        </div>
    </div>
</section>



