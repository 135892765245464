import { Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Lightbox } from 'ngx-lightbox';
import * as $ from 'jquery';

@Component({
  selector: 'app-vulture',
  templateUrl: './vulture.component.html',
  styleUrls: ['./vulture.component.scss']
})
export class VultureComponent implements AfterViewInit {


  thiriumVultureImages = [
    {
      src: 'assets/img/Thirium Vulture/2.jpg',
      caption: 'Image 2',
      thumb: 'assets/img/Thirium Vulture/2.jpg'
    },
    {
      src: 'assets/img/Thirium Vulture/3.jpg',
      caption: 'Image 3',
      thumb: 'assets/img/Thirium Vulture/3.jpg'
    },
    {
      src: 'assets/img/Thirium Vulture/4.jpg',
      caption: 'Image 4',
      thumb: 'assets/img/Thirium Vulture/4.jpg'
    },
    {
      src: 'assets/img/Thirium Vulture/5.jpg',
      caption: 'Image 5',
      thumb: 'assets/img/Thirium Vulture/5.jpg'
    },
    {
      src: 'assets/img/Thirium Vulture/6.jpg',
      caption: 'Image 6',
      thumb: 'assets/img/Thirium Vulture/6.jpg'
    }
  ];
  

  constructor() { }

  ngAfterViewInit(): void {
    // your code here
  }

  ngOnInit(): void {
  }

  openLightbox(image: any) {
    const modal = document.getElementById('lightbox-modal');
    const imageElement = document.getElementById('lightbox-image');
  
    // Get the clicked image source and caption
    const src = image.src;
    const caption = image.caption;
  
    // Set the source and caption for the enlarged image
    imageElement.setAttribute('src', src);
    imageElement.setAttribute('alt', caption);
  
    // Show the modal
    modal.classList.add('uk-open');
  }

}
