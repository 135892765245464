<!-- Start Preloader Area -->
<div class="uk-preloader">
    <div class="spinner">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
    </div>
</div>
<!-- End Preloader Area -->

<app-header></app-header>
    <router-outlet></router-outlet>
<app-footer></app-footer>