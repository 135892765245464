<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>Project Details</h1>
        <ul>
            <li><a routerLink="/">Home</a></li>
            <li>Project Details</li>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start Project Details Area -->
<section class="rugged-pc-area uk-rugged-pcs uk-section">
    <div class="uk-container">
        <div class="uk-grid uk-flex project-details">
            <div class="project-details-img uk-width-expand">
                <img src="assets/img/integrate.jpg" alt="image">
            </div>

            <div class="item uk-width-1-5">
                <div class="project-details-info">
                    <ul>
                        <li><Span>Customer Name:</Span> Resolute ISR</li>
                        <li><Span>Category:</Span> Integration Support</li>
                        <li><Span>Date:</Span> 06/2021</li>
                        <li><Span>Status:</Span> Completed</li>
                        <!--<li><Span>Demo Link:</Span> <a href="#">www.gunter.com</a></li>-->
                        <li><Span>Tags:</Span> <a>UAV</a>, <a>UAS</a>, <a>Ground Control Station</a>, <a>Mobile</a>, <a>Powerful</a>, <a>Custom Development</a></li>
                        <li>
                            <Span>Share our project:</Span>

                            <ul>
                               <!-- <li><a href="#"><i class="flaticon-logo"></i></a></li>
                                <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                                <li><a href="#"><i class="flaticon-logo-1"></i></a></li>-->
                                <li><a href="https://www.linkedin.com/sharing/share-offsite/?url=https://thirium.com/gcs"><i class="flaticon-linkedin"></i></a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="rugged-pcs-desc">
            <h3>Mobile Ground Control Station</h3>
            <p>This system was selected by Resolute ISR as their primary mobile groundstation which is utilized to fly Unmanned Aerial Systems operationally in some of the harshest enviroments in the world.</p>
            <p>In addition to the high performance computer, we integrated a Serial to Ethernet converter, networking switch and complete PGCS (ground side Piccolo autopilot, Novatel gps, etc). It is 100% mobile at 33 pounds and has all equipment necessary to fly a military drone from the back of a pickup truck.</p>
            <h4>Base Specs:</h4>
            <li>Waterproof & Dustproof when closed for travel</li>
            <li>Built-in 17.3-inch 144Hz monitor (optional)</li>
            <li>Up to 16TB of storage (Solid State Only)</li>
            <li>High Performance (Ryzen 7 3800X or better)</li>
            <li>Gaming level graphics (2060 or better)</li>
            <li>Built-in Wi-Fi (optional)</li>
            <li>Connect up to 4 monitors</li>
            <li>Carryon size with wheels for convenience</li>
            <li>Durable enough to check it as luggage on an airplane</li>
            <li>Set up in less than a minute</li>
            <li>All connectors accessible from top panel</li>
                
           

        </div>

        <div class="project-next-and-prev">
            <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-2@s">
                <div class="item">
                    <a href="rugged-pc" class="uk-button uk-button-default">Prev Project</a>
                </div>

                <div class="item uk-text-right">
                    <a href="integration" class="uk-button uk-button-default">Next Project</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Project Details Area -->
