import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { DigitalAgencyComponent } from './components/pages/digital-agency/digital-agency.component';
import { DigitalAgencyTwoComponent } from './components/pages/digital-agency-two/digital-agency-two.component';
import { DigitalAgencyThreeComponent } from './components/pages/digital-agency-three/digital-agency-three.component';
import { ProjectsComponent } from './components/pages/projects/projects.component';
import { ProjectDetailsComponent } from './components/pages/project-details/project-details.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { ServiceDetailsComponent } from './components/pages/service-details/service-details.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { TeamComponent } from './components/pages/team/team.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import { AboutComponent } from './components/pages/about/about.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { DigitalAgencyFourComponent } from './components/pages/digital-agency-four/digital-agency-four.component';
import { BusinessStartupsComponent } from './components/pages/business-startups/business-startups.component';
import { SeoAgencyComponent } from './components/pages/seo-agency/seo-agency.component';
import { CorporateAgencyComponent } from './components/pages/corporate-agency/corporate-agency.component';
import { DigitalMarketingComponent } from './components/pages/digital-marketing/digital-marketing.component';
import { BusinessConsultingComponent } from './components/pages/business-consulting/business-consulting.component';
import { CreativeAgencyComponent } from './components/pages/creative-agency/creative-agency.component';
import { RuggedPCComponent } from './project-details/RuggedPC/rugged-pc.component';
import { GcsComponent } from './project-details/GCS/gcs.component';
import { IntegrationComponent } from './project-details/integration/integration.component';
import { FutureComponent } from './project-details/future/future.component';
import { CustomDevelopmentComponent } from './service-details/custom-development/custom-development.component';
import { IntegrationsComponent } from './service-details/integrations/integrations.component';
import { TrainingComponent } from './service-details/training/training.component';
import { StandardizationComponent } from './service-details/standardization/standardization.component';
import { ConsultingComponent } from './service-details/consulting/consulting.component';
import { PilotComponent } from './service-details/pilot/pilot.component';
import { ChatComponent } from './components/pages/chat/chat.component';
import { FormsModule } from '@angular/forms'; // Add this import for FormsModule
import { RouterModule } from '@angular/router'; // Add this import for RouterModule
import { HttpClientModule } from '@angular/common/http'; 
import { LightboxModule } from 'ngx-lightbox';
import { LocationComponent } from './location/location.component';
import { RestrictedComponent } from './restricted/restricted.component';
// import { AccessGuard } from './Services/AccessGuard.service';
import { CareersComponent } from './careers/careers.component';
import { GCSComponent } from './gcs/gcs.component';
import { RedlineComponent } from './redline/redline.component';
import { ThriumlinkComponent } from './thriumlink/thriumlink.component';
import { VultureComponent } from './vulture/vulture.component';
import { UastrackproComponent } from './uastrackpro/uastrackpro.component';
import { OurservicesComponent } from './ourservices/ourservices.component';
import { PiccoloComponent } from './piccolo/piccolo.component';
import { AbouthomeComponent } from './abouthome/abouthome.component';
import { NewsComponent } from './news/news.component';
import { ServicesmenuComponent } from './servicesmenu/servicesmenu.component';
import { SofwaredevelopmentComponent } from './sofwaredevelopment/sofwaredevelopment.component';
import { HoodtechComponent } from './hoodtech/hoodtech.component';




@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    DigitalAgencyComponent,
    DigitalAgencyTwoComponent,
    DigitalAgencyThreeComponent,
    ProjectsComponent,
    ProjectDetailsComponent,
    ServicesComponent,
    ServiceDetailsComponent,
    BlogComponent,
    BlogDetailsComponent,
    TeamComponent,
    TestimonialsComponent,
    AboutComponent,
    ContactComponent,
    DigitalAgencyFourComponent,
    BusinessStartupsComponent,
    SeoAgencyComponent,
    CorporateAgencyComponent,
    DigitalMarketingComponent,
    BusinessConsultingComponent,
    CreativeAgencyComponent,
    RuggedPCComponent,
    GcsComponent,
    IntegrationComponent,
    FutureComponent,
    CustomDevelopmentComponent,
    IntegrationsComponent,
    TrainingComponent,
    StandardizationComponent,
    ConsultingComponent,
    PilotComponent,
    ChatComponent,
    RestrictedComponent,
    // LocationComponent,
    CareersComponent,
    GCSComponent,
    RedlineComponent,
    ThriumlinkComponent,
    VultureComponent,
    UastrackproComponent,
    OurservicesComponent,
    PiccoloComponent,
    AbouthomeComponent,
    NewsComponent,
    ServicesmenuComponent,
    SofwaredevelopmentComponent,
    HoodtechComponent
   
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule, // Add FormsModule to the imports array
    RouterModule,
    HttpClientModule,
    LightboxModule
  ],
  
  // providers: [LocationComponent, AccessGuard],
  bootstrap: [AppComponent],
  
})
export class AppModule { }
