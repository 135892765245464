


<!-- Start Services Details Area -->
<section class="services-details-area uk-services-details uk-section">
  <div class="uk-container">
      <article class="uk-article services-details">
          <div class="uk-grid uk-flex">

              <div class="uk-sidebar uk-width-1-5 uk-flex-first@l uk-first-column">
                  <!-- Sidebar content (like app-servicesmenu) can go here -->
                  <app-servicesmenu></app-servicesmenu>
                  <!-- Other sidebar widgets can go here -->
              </div>

              <div class="inner uk-width-expand">
                  <div class="services-details-desc">

                    <!-- Start Thirium Vulture Introduction Area -->
<section class="payloads-intro uk-section">
  <div class="uk-container">
      <h2 class="uk-text-center">Thirium Vulture UAS</h2>
      <p class="uk-text-center">
          The Thirium Vulture represents the pinnacle of our innovation in the UAS industry. This state-of-the-art system, currently in development, promises to redefine industry standards with its unparalleled capabilities. However, due to the groundbreaking nature of its features, the specifics of the Thirium Vulture remain confidential.
      </p>
      <p class="uk-text-center">
          To delve deeper into the Thirium Vulture's capabilities, we require interested entities to sign a Non-Disclosure Agreement (NDA). This ensures the exclusivity and security of our advanced technologies, while fostering collaboration with partners who share our values of discretion and innovation.
      </p>
      <div class="uk-text-center">
          <a href="/contact" class="uk-button uk-button-default">Request NDA</a>
      </div>
  </div>
</section>
<br>
<!-- End Thirium Vulture Introduction Area -->

                      <h3>Unveiling the Future: Thirium Vulture</h3>
                      <p>Our teams are diligently working on the Thirium Vulture, a UAS system that's set to revolutionize the industry. Designed for exceptional performance, reliability, and versatility, the Vulture is a testament to Thirium's commitment to pushing the boundaries of what's possible in aerial technology.</p>
                      <p>While we eagerly anticipate its completion, the release date is contingent upon funding and further advancements in our proprietary technologies. We assure you, the wait will be worth it.</p>
                      <p>For a deeper understanding and potential collaboration, please reach out to us under the protection of our Mutual NDA.</p>

                      <div class="services-image-slides owl-carousel owl-theme">
                          <div class="item">
                              <img src="assets/img/Thirium Vulture/1.jpg" alt="Thirium Vulture Image">
                          </div>
                          <!-- Additional images can be added here -->
                      </div>

                      <!-- Image Gallery -->
                      <h3>Image Gallery</h3>
                      <div uk-slider="finite: true">
                          <div class="uk-position-relative">
                              <ul class="uk-slider-items uk-child-width-1-2 uk-child-width-1-4@s uk-grid-small uk-grid-match" uk-grid>
                                  <li *ngFor="let image of thiriumVultureImages">
                                      <a [href]="image.src" data-fancybox="gallery">
                                          <div class="uk-card uk-card-default uk-card-hover">
                                              <div class="uk-card-media-top">
                                                  <img [src]="image.thumb" [alt]="image.caption" class="fancybox__image" (click)="openLightbox(image)">
                                              </div>
                                          </div>
                                      </a>
                                  </li>
                              </ul>
                              <a class="uk-position-center-left uk-position-small" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
                              <a class="uk-position-center-right uk-position-small" href="#" uk-slidenav-next uk-slider-item="next"></a>
                          </div>
                          <ul class="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>
                      </div>
                  </div>
              </div>
          </div>
      </article>
  </div>
</section>
<!-- End Services Details Area -->

<app-contact></app-contact>
