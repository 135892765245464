import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hoodtech',
  templateUrl: './hoodtech.component.html',
  styleUrls: ['./hoodtech.component.scss']
})
export class HoodtechComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
