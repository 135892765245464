

<!-- Start Services Details Area -->
<section class="services-details-area uk-services-details uk-section">
    <div class="uk-container">
        <article class="uk-article services-details">
            <div class="uk-grid uk-flex">

                <div class="uk-sidebar uk-width-1-5 uk-flex-first@l uk-first-column">
                    <!-- Sidebar content (like app-servicesmenu) can go here -->
                    <app-servicesmenu></app-servicesmenu>
                    <!-- Other sidebar widgets can go here -->
                </div>

                <div class="inner uk-width-expand">
                    <div class="services-details-desc">

<!-- Start Payloads Introduction Area -->
<section class="payloads-intro uk-section">
    <div class="uk-container">
        <h2 class="uk-text-center">Thirium Payloads</h2>
        <p class="uk-text-center">
            At Thirium, we pride ourselves on pushing the boundaries of innovation. Our payloads are a testament to our commitment to advancing the UAS industry. While we have developed state-of-the-art solutions, the specifics of these advancements remain confidential to maintain our competitive edge and ensure security.
        </p>
        <p class="uk-text-center">
            To gain deeper insights into our proprietary technologies, we require interested parties to sign a Non-Disclosure Agreement (NDA). This ensures that our groundbreaking developments remain exclusive, while also allowing us to collaborate with entities that value discretion and innovation as much as we do.
        </p>
        <div class="uk-text-center">
            <a href="/contact" class="uk-button uk-button-default">Request NDA</a>
        </div>
    </div>
</section>
<br>
<!-- End Payloads Introduction Area -->

                        <h3>Confidential UAS Tech</h3>
                        <p>Thirium Engineering, a beacon of excellence in technological innovation, has meticulously crafted a custom system for an esteemed client. With unwavering dedication and expertise, our seasoned professionals have poured countless hours into perfecting this cutting-edge solution. Combining rigorous research, design precision, and exhaustive testing, we have achieved unrivaled performance, reliability, and adaptability. While confidentiality prevents us from disclosing specific details, this remarkable collaboration showcases our commitment to surpassing expectations and setting new industry standards. Thirium Engineering remains at the forefront of transformative advancements, empowering clients with game-changing solutions that redefine possibilities.</p>

                        <div class="services-image-slides owl-carousel owl-theme">
                            <div class="item">
                                <img src="assets/img/Thirium Box/thirium_box.jpg" alt="Large Image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </article>
    </div>
</section>
<!-- End Services Details Area -->
<app-hoodtech></app-hoodtech>

<div class="separate uk-dark">
    <div class="br-line"></div>
</div>

<app-contact></app-contact>
