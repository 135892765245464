import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rugged-pc',
  templateUrl: './rugged-pc.component.html',
  styleUrls: ['./rugged-pc.component.scss']
})
export class RuggedPCComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
