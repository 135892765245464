<div class="chat-container">
    <button class="uk-button uk-button-primary uk-button-small" (click)="toggleChatPopup()" [ngClass]="{'hidden': isOpen}">
      <span uk-icon="icon: comments"></span> Need Help?
    </button>
    <div class="chat-popup" [ngClass]="{'hidden': !isOpen}">
        <div class="uk-card uk-card-default uk-card-small uk-card-body">
          <div class="uk-card-header">
            <h3 class="uk-card-title uk-margin-remove-bottom header-title">Chat with our smart AI for instant answers. </h3>
            <button class="uk-align-right" (click)="toggleChatPopup()" uk-close></button>
          </div>
        <div class="chat-history uk-card-body" #scrollMe>
          <ng-container *ngFor="let entry of chatHistory">
            <div [ngClass]="{'user-message': entry.user, 'assistant-message': !entry.user}">
              {{ entry.message }}
              <div *ngIf="entry.isTyping && this.isTyping" class="typing-indicator">
                <span class="typing-indicator">Typing...</span>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="uk-card-footer">
          <div class="uk-inline uk-width-1-1">
            <input [(ngModel)]="userMessage" (keyup.enter)="onSendMessage()" placeholder="Type your message here..." class="uk-input uk-form-small"><br><br>
            <button class="uk-button uk-button-primary uk-button-small" (click)="onSendMessage()">Send</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  