import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-servicesmenu',
  templateUrl: './servicesmenu.component.html',
  styleUrls: ['./servicesmenu.component.scss']
})
export class ServicesmenuComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
