<!-- Start Main Banner -->
<div id="home" class="uk-banner uk-dark main-banner item-bg2">
    <div class="video-background">
        <video onloadedmetadata="this.muted=true" autoplay loop  preload="auto">
            <source src="assets/video/finalweb.mp4" type="video/mp4">
        </video>
    </div>
    <div class="content-overlay">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="uk-container">
                    <div class="main-banner-content">
                        <h1>Innovative <br>Technologies</h1>
                        <p>Thirium is dedicated to producing exceptional and functional products. From speed and durability to cutting-edge design and futuristic technologies, 
                            our products exceed expectations.<br><br> 
                            It's not theory, it's Thirium.</p>
                        <a routerLink="about" class="uk-button uk-button-default">About</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Main Banner -->


<!-- Start Partner Area -->
<div class="partner-area uk-section uk-dark uk-padding-remove-top">
    <div class="uk-container">
      <!--  <span>What We Do</span> -->
     <h4>Trusted Partners & Associated Publications</h4>
     <div class="partner-slides owl-carousel owl-theme">
        <div class="item">
            <a href="https://www.unmannedsystemstechnology.com/company/thirium/">
                <img src="assets/img/ust-logo-2019.PNG" alt="image">
            </a>
        </div>
       
            <div class="item">
                <a href="https://fluixpro.com/">
                    <img src="assets/img/partner-white-one.png" alt="image">
                </a>
            </div>

            <!-- <div class="item">
                <a href="https://resoluteisr.com/">
                    <img src="assets/img/partner-white-two.png" alt="image">
                </a>
            </div> -->

            <div class="item">
                <a href="https://councils.forbes.com/profile/Austin-Lawson-President-Thirium-Inc/752689b1-b1aa-45d4-8861-9b7ea5fa51f3">
                    <img src="assets/img/forbes.jpg" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="https://www.entrepreneur.com/author/austin-lawson">
                    <img src="assets/img/partner-white-five.png" alt="image">
                </a>
            </div>

            <!-- <div class="item">
                <a href="https://edgybees.com/">
                    <img src="assets/img/partner-white-four.png" alt="image">
                </a>
            </div> -->

            <div class="item">
                <a href="https://advancedcomputersofknoxville.com/">
                    <img src="assets/img/partner-white-three.png" alt="image">
                </a>
            </div>

            <!-- <div class="item">
                <a href="https://militarycontractor.com/">
                    <img src="assets/img/milcon.jpg" alt="image">
                </a>
            </div> -->
         

           <!-- <div class="item">
                <a href="#">
                    <img src="assets/img/partner-white-six.png" alt="image">
                </a>
            </div> -->
        </div>
    </div>
</div>
<!-- End Partner Area -->


<app-piccolo></app-piccolo>
<div class="separate uk-dark">
    <div class="br-line"></div>
</div>
<app-ourservices></app-ourservices>
<!-- <app-gcs1></app-gcs1>
<app-redline></app-redline>
<app-thriumlink></app-thriumlink>
<app-vulture></app-vulture>
<app-uastrackpro></app-uastrackpro> -->



<div class="separate uk-dark">
    <div class="br-line"></div>
</div>

 

<!-- Start Feedback Area -->
<!-- <section id="clients" class="feedback-area uk-dark uk-section uk-feedback">
    <div class="uk-container">
      <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
        <div class="item">
          <div class="feedback-img">
            <img src="assets/img/brandtest.png" alt="image">
          </div>
        </div>
        <div class="item">
          <div class="uk-section-title section-title">
            <span>Let's Talk</span>
            <h2>Get in Touch</h2>
          </div>
  
          <form #contactForm id="contactForm" action="https://formspree.io/f/mvonjnvr" method="POST">
            <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
              <div class="item uk-margin">
                <input type="text" class="uk-input" name="name" id="name" placeholder="Name">
              </div>
              <div class="item uk-margin">
                <input type="email" class="uk-input" name="email" id="email" placeholder="Email">
              </div>
              <div class="item uk-margin">
                <input type="text" class="uk-input" placeholder="Phone">
              </div>
              <div class="item uk-margin">
                <input type="text" class="uk-input" name="subject" id="subject" placeholder="Subject">
              </div>
            </div>
            <div class="item">
              <textarea name="message" class="uk-textarea" id="message" cols="30" rows="4" placeholder="Your Message"></textarea>
            </div>
            <input type="hidden" name="_replyto" value="alawson@thirium.com">
            <button type="submit" class="uk-button uk-button-default">Submit Message</button>
          </form>
        </div>
      </div>
    </div>
  </section> -->
<!-- End Feedback Area -->
<app-news></app-news>

 <app-chat></app-chat>
 <!-- <app-location></app-location> -->
























