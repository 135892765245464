import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-uastrackpro',
  templateUrl: './uastrackpro.component.html',
  styleUrls: ['./uastrackpro.component.scss']
})
export class UastrackproComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
