import { Component, ViewChild, ElementRef } from '@angular/core';
import { ChatService } from '../services/chat.service';


@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent {
  
  public userMessage = '';
  public chatHistory: { user: boolean; message: string; isTyping?: boolean; data?: string }[] = [];
  public isOpen = false;
  public isTyping = false;
  @ViewChild('scrollMe') private myScrollContainer!: ElementRef;
  private pilotDataToDownload: any[] = [];

  constructor(private chatService: ChatService) {}

  onSendMessage() {
    console.log('onSendMessage called with userMessage:', this.userMessage);

    if (this.userMessage.trim().length === 0) return;
    console.log('User message in onSendMessage():', this.userMessage);

    let identifiedCollections: string[] = [];

 

    this.isTyping = true;

    this.chatHistory.push({ user: true, message: this.userMessage, isTyping: true });
    this.scrollToBottom();

    this.chatService.sendMessage(this.userMessage).subscribe(
      (response) => {
        console.log("Assistant response:", response);
        console.log("Message object:", response.choices[0].message);

        setTimeout(() => {
          const assistantMessage = response.choices && response.choices[0] && response.choices[0].message && response.choices[0].message.content
          ? response.choices[0].message.content.trim()
          : 'I am sorry, I could not understand your question.';
          console.log('Response from sendMessage():', response);

          console.log('Actual number of tokens used:', response.usage.total_tokens);

          console.log("Assistant message:", assistantMessage);
          this.chatHistory.push({ user: false, message: assistantMessage, isTyping: false });
          console.log('Assistant message:', assistantMessage);

          this.isTyping = false;
          this.scrollToBottom();
  
          this.isTyping = false;
          this.scrollToBottom();
  
       
  
          this.userMessage = '';
        }, Math.random() * 1000 + 260);
      },
      (error) => {
        console.error('Error in onSendMessage:', error);
        this.chatHistory.push({ user: false, message: 'An error occurred while processing your message.', isTyping: false });
        this.isTyping = false;
        this.scrollToBottom();
      },
      () => {
        this.isTyping = false;
      }
    );
  }

  toggleChatPopup() {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      setTimeout(() => {
        this.scrollToBottom();
      }, 100);
    }
  }

  scrollToBottom(): void {
    try {
      const isScrolledToBottom =
        this.myScrollContainer.nativeElement.scrollHeight -
          this.myScrollContainer.nativeElement.scrollTop ===
        this.myScrollContainer.nativeElement.clientHeight;
      if (isScrolledToBottom || this.isTyping) {
        this.myScrollContainer.nativeElement.scrollTop =
          this.myScrollContainer.nativeElement.scrollHeight;
          console.log('isTyping:', this.isTyping);

      }
    } catch (err) {}
  }

  

  exportToExcel(data: string, filename: string): void {
    const formattedData = data
      .split('\n')
      .map((row) => `'${row.split(':').join(',')}`) // Add a single quote before the equal sign
      .join('\n');
    const mimeType = 'text/csv;charset=utf-8';
    const blob = new Blob([formattedData], { type: mimeType });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  
  
  
  

}
