import { Component, AfterViewInit, ViewChild, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
declare var UIkit: any;

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss']
})
export class CareersComponent implements AfterViewInit {
  @ViewChildren('card') cards: QueryList<ElementRef>;
  ctoApplication = { email: '', resume: null };
directorBdApplication = { email: '', resume: null };
pilotPiccoloApplication = { email: '', resume: null };
dronePilotApplication = { email: '', resume: null };
mechanicVtolApplication = { email: '', resume: null };


  

  constructor(private http: HttpClient) {}

  submitForm(form: NgForm, jobRole: string, application) {
    const formData = new FormData();
  
    formData.append('jobRole', jobRole);
    formData.append('email', application.email);
    formData.append('resume', application.resume, application.resume.name);
  
    this.http.post('https://formspree.io/f/mjvdnbvd', formData)
      .subscribe(
        response => {
          // Handle success
          form.reset();
          UIkit.modal(`#modal-${jobRole}`).hide(); // Close the modal
          console.log('Form submission successful:', response);
          alert('Thank you! Your application has been submitted.');
          application.email = '';
          application.resume = null;
        },
        error => {
          // Handle error
          console.error('Form submission failed:', error);
          alert('Oops! Something went wrong.');
        }
      );
  }
  
  ngAfterViewInit() {
    this.cards.forEach(card => {
        UIkit.tooltip(card.nativeElement);
    });
}

onFileChange(event, application) {
  if (event.target.files.length > 0) {
    application.resume = event.target.files[0];
  }
}

}