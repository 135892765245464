import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  allArticles = [
    {
      title: "Thirium has landed in Ozark",
      link: "https://dothaneagle.com/news/local/business/thirium-ozark-unmanned-aviation/article_e7db3000-3c21-11ee-958c-b32991403ae1.html",
      imgSrc: "https://bloximages.newyork1.vip.townnews.com/dothaneagle.com/content/tncms/assets/v3/editorial/7/54/754b15d6-3c22-11ee-8e06-ab537269c05e/64dcaa2ac8940.image.jpg?resize=624%2C500",
      excerpt: "An unmanned aviation company has landed at the Ozark Airport-Blackwell Field.",
      date: "August 16, 2023",
      featured: true
    },
    {
      title: "The Operator's Edge: A New Perspective on UAS Operations",
      link: "https://unmanned-network.com/the-operators-edge-a-new-perspective-on-uas-operations/",
      imgSrc: "assets/img/news/operator.png",
      excerpt: "The role of the operator is paramount and often underestimated.",
      date: "August 28, 2023",
      featured: true
    },
    {
      title: "The UAS Dilemma: Why Our Drones Are Falling Short (and How to Fix It)",
      link: "https://unmanned-network.com/the-uas-dilemma-why-our-drones-are-falling-short-and-how-to-fix-it/",
      imgSrc: "https://unmanned-network.com/wp-content/uploads/elementor/forms/64f9c8e8ccf84.png",
      excerpt: "Sending 5-10 soldiers into hostile territories, sometimes 70 miles away ",
      date: "September 7, 2023",
      featured: true
    },
    {
      title: "Lost in Translation: Does the US Government Truly Understand Its UAS Needs?",
      link: "https://www.linkedin.com/pulse/lost-translation-does-us-government-truly-understand-its-lawson",
      imgSrc: "assets/img/news/translation.jpeg",
      excerpt: "Before launching our VTOL UAS, we would coordinate with a local version of ATC.",
      date: "September 1, 2023",
      featured: false
    },
    {
      title: "Unveiling the UAS Mirage: A Call for Transparency in Marketing",
      link: "https://www.linkedin.com/pulse/unveiling-uas-mirage-call-transparency-marketing-austin-lawson",
      imgSrc: "assets/img/news/mirage.jpeg",
      excerpt: "As leaders in the UAS industry, it is our responsibility to provide accurate and realistic expectations of our systems' capabilities.",
      date: "September 4, 2023",
      featured: false
    }
  ];
  
  featuredArticles = this.allArticles.slice(0, 3);
  otherArticles = this.allArticles.slice(3);
  displayedOtherArticles = this.otherArticles.slice(0, 3);

  showMoreArticles() {
    const currentLength = this.displayedOtherArticles.length;
    this.displayedOtherArticles = this.displayedOtherArticles.concat(this.otherArticles.slice(currentLength, currentLength + 3));
  }

  ngOnInit(): void {
    // Extract and keep the featured articles
    this.featuredArticles = this.allArticles.filter(article => article.featured);
  
    // Extract other articles and sort them by date
    const sortedOtherArticles = this.allArticles
      .filter(article => !article.featured)
      .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
  
    this.otherArticles = sortedOtherArticles;
    this.displayedOtherArticles = this.otherArticles.slice(0, 3);
  }
}