import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-redline',
  templateUrl: './redline.component.html',
  styleUrls: ['./redline.component.scss']
})
export class RedlineComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }


  

}
