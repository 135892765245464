<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>Team</h1>
        <ul>
            <li><a routerLink="/">Home</a></li>
            <li>Team</li>
        </ul>
    </div>
</section>

<section class="team-area uk-team uk-section">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            <div class="single-team">
                <ul class="team-social">
                    <li><a href="#"><i class="flaticon-logo"></i></a></li>
                    <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                    <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                    <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
                </ul>

                <img src="assets/img/Team/austin.jpg" alt="image">

                <div class="team-content">
                    <h3>Austin Lawson</h3>
                    <span>Founder &amp; CEO</span>
                </div>
            </div>

            <div class="single-team">
                <ul class="team-social">
                    <li><a href="#"><i class="flaticon-logo"></i></a></li>
                    <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                    <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                    <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
                </ul>

                <img src="assets/img/Team/jon.jpg" alt="image">

                <div class="team-content">
                    <h3>Jon Malnero</h3>
                    <span>Founder &amp; COO</span>
                </div>
            </div>

            <div class="single-team">
                <ul class="team-social">
                    <li><a href="#"><i class="flaticon-logo"></i></a></li>
                    <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                    <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                    <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
                </ul>

                <img src="assets/img/Team/alana.jpg" alt="image">

                <div class="team-content">
                    <h3>Alana K. Vick, CPA</h3>
                    <span>Chief Financial Officer</span>
                </div>
            </div>

            <div class="single-team">
                <ul class="team-social">
                    <li><a href="#"><i class="flaticon-logo"></i></a></li>
                    <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                    <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                    <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
                </ul>

                <img src="assets/img/Team/julia.jpg" alt="image">

                <div class="team-content">
                    <h3>Julia Hurley</h3>
                    <span>Innovator &amp; Investor</span>
                </div>
            </div>
        </div>
    </div>
</section>

