import { Component, OnInit } from '@angular/core';
import { AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements AfterViewInit {
  @ViewChild('contactForm') contactForm: ElementRef;

  constructor(private http: HttpClient) { }

  submitForm(form: NgForm) {
    const formData = {
      name: form.value.name,
      email: form.value.email,
      phone: form.value.phone,
      subject: form.value.subject,
      message: form.value.message,
      _replyto: 'alawson@thirium.com' // Email address to receive form submissions
    };

    // Make a POST request to Formspree API
    this.http.post('https://formspree.io/f/mvonjnvr', formData)
      .subscribe(
        response => {
          // Handle success
          form.reset(); // Reset the form
          console.log('Form submission successful:', response);
          alert('Thank you! Your message has been sent.');
        },
        error => {
          // Handle error
          console.error('Form submission failed:', error);
          alert('Oops! Something went wrong.');
        }
      );
  }

  ngAfterViewInit() {
    const form = this.contactForm.nativeElement;

    // Attach event listener on form submission
    form.addEventListener('submit', event => {
      event.preventDefault(); // Prevent the form from submitting normally

      // Send the form data via Formspree
      const xhr = new XMLHttpRequest();
      xhr.open('POST', form.action);
      xhr.setRequestHeader('Accept', 'application/json');
      xhr.onreadystatechange = () => {
        if (xhr.readyState !== XMLHttpRequest.DONE) return;
        if (xhr.status === 200) {
          // Success message
          form.reset();
          alert('Thank you! Your message has been sent.');
        } else {
          // Error message
          alert('Oops! Something went wrong.');
        }
      };
      xhr.send(new FormData(form));
    });
  }
}