<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>Future Projects</h1>
        <ul>
            <li><a routerLink="/">Home</a></li>
            <li>Future-Projects</li>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start Project Details Area -->
<section class="rugged-pc-area uk-rugged-pcs uk-section">
    <div class="uk-container">
        <div class="uk-grid uk-flex project-details">
            <div class="project-details-img uk-width-expand">
                <img src="assets/img/integrate.jpg" alt="image">
            </div>

            <div class="item uk-width-1-5">
                <div class="project-details-info">
                    <ul>
                        <li><Span>Customer Name:</Span> Thirium</li>
                        <li><Span>Category:</Span> Custom Development</li>
                        <li><Span>Date:</Span> 2022-2023</li>
                        <li><Span>Status:</Span> WIP</li>
                        <!--<li><Span>Demo Link:</Span> <a href="#">www.gunter.com</a></li>-->
                        <li><Span>Tags:</Span> <a>UAV</a>, <a>UAS</a>, <a>Gaming</a>, <a>Rugged Systems</a>, <a>Powerful</a>, <a>Custom Development</a></li>
                        <li>
                            <Span>Share our project:</Span>

                            <ul>
                               <!-- <li><a href="#"><i class="flaticon-logo"></i></a></li>
                                <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                                <li><a href="#"><i class="flaticon-logo-1"></i></a></li>-->
                                <li><a href="https://www.linkedin.com/sharing/share-offsite/?url=https://thirium.com/future-projects"><i class="flaticon-linkedin"></i></a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="rugged-pcs-desc">
            <h3>Future Projects</h3>
            <p>At Thirium, we are constantly working on new and innovative development projects to stay ahead in a competitive industry. 
                While we can't openly share the details of many of these projects without a signed non-disclosure agreement (NDA), we welcome the 
                opportunity to have a conversation with interested companies.</p>
            
            <p>A conversation is never a waste of time, and you never know where it may lead. If you are curious about what we are working on or have planned, 
                we encourage you to reach out and schedule a conversation with us. After we have an executed mutual NDA in place, we would be happy to discuss our current and future projects and 
                explore how we can work together.</p>
            
                
           

        </div>

        <div class="project-next-and-prev">
            <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-2@s">
                <div class="item">
                    <a href="gcs" class="uk-button uk-button-default">Prev Project</a>
                </div>

                <div class="item uk-text-right">
                    <a href="rugged-pc" class="uk-button uk-button-default">Next Project</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Project Details Area -->