<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>Projects</h1>
        <ul>
            <li><a href="index-4.html">Home</a></li>
            <li>Projects</li>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start Project Area -->
<section id="project" class="project-area uk-project uk-section">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
           

            <div class="single-project">
                <a routerLink="/rugged-pc" class="project-img">
                    <img src="assets/img/project1.jpg" alt="image">
                </a>
    
                <div class="project-content">
                    <h3><a routerLink="/rugged-pc">Ruggedized Dual PC</a></h3>
                    <ul>
                        <li><a href="rugged-pc">Windows</a></li>
                        <li><a href="rugged-pc">Linux</a></li>
                    </ul>
                </div>
            </div>
    
            <div class="single-project">
                <a routerLink="/gcs" class="project-img">
                    <img src="assets/img/project2.jpg" alt="image">
                </a>
    
                <div class="project-content">
                    <h3><a routerLink="/gcs">Mobile Ground Control Station</a></h3>
                    <ul>
                        <li><a href="gcs">UAS</a></li>
                        <li><a href="gcs">Drone</a></li>
                    </ul>
                </div>
            </div>

            <div class="single-project">
                <a routerLink="/future-projects" class="project-img">
                    <img src="assets/img/project14.png" alt="image">
                </a>
    
                <div class="project-content">
                    <h3><a routerLink="/future-projects">Planned Future Projects</a></h3>
                    <ul>
                        <li><a href="future-projects">Redacted</a></li>
                        <li><a href="future-projects">NDA Needed</a></li>
                    </ul>
                </div>
            </div>
    

            <!--<div class="single-project">
                <a routerLink="/project-details" class="project-img">
                    <img src="assets/img/project3.jpg" alt="image">
                </a>

                <div class="project-content">
                    <h3><a routerLink="/project-details">UX/UI Design</a></h3>
                    <ul>
                        <li><a href="#">Web</a></li>
                        <li><a href="#">Mobile</a></li>
                    </ul>
                </div>
            </div>

            <div class="single-project">
                <a routerLink="/project-details" class="project-img">
                    <img src="assets/img/project2.jpg" alt="image">
                </a>

                <div class="project-content">
                    <h3><a routerLink="/project-details">Architecture</a></h3>
                    <ul>
                        <li><a href="#">Home</a></li>
                        <li><a href="#">Bridge</a></li>
                    </ul>
                </div>
            </div>-->
        </div>

       <!-- <div class="pagination-area">
            <ul class="uk-pagination uk-flex-center">
                <li><a href="#"><span class="flaticon-back"></span></a></li>
                <li><a href="#">1</a></li>
                <li><a href="#">2</a></li>
                <li class="uk-active"><a href="#">3</a></li>
                <li><a href="#">4</a></li>
                <li><a href="#"><span class="flaticon-right"></span></a></li>
            </ul>
        </div>-->
    </div>
</section>
<!-- End Project Area -->