import { Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { Lightbox } from 'ngx-lightbox';
import * as $ from 'jquery';



@Component({
  selector: 'app-digital-agency',
  templateUrl: './digital-agency.component.html',
  styleUrls: ['./digital-agency.component.scss']
})
export class DigitalAgencyComponent implements AfterViewInit {
  @ViewChild('contactForm') contactForm: ElementRef;
  
 

  

  
  

  constructor(private http: HttpClient, private lightbox: Lightbox) { }

  submitForm(form: NgForm) {
    const formData = {
      name: form.value.name,
      email: form.value.email,
      phone: form.value.phone,
      subject: form.value.subject,
      message: form.value.message,
      _replyto: 'alawson@thirium.com' // Email address to receive form submissions
    };

    // Make a POST request to Formspree API
    this.http.post('https://formspree.io/f/mvonjnvr', formData)
      .subscribe(
        response => {
          // Handle success
          form.reset(); // Reset the form
          console.log('Form submission successful:', response);
          alert('Thank you! Your message has been sent.');
        },
        error => {
          // Handle error
          console.error('Form submission failed:', error);
          alert('Oops! Something went wrong.');
        }
      );
  }

  ngAfterViewInit() {
    

        // Initialize Owl Carousel
        $('.owl-carousel').owlCarousel({
          items: 4, // Number of items to show at a time
          loop: true, // Enable infinite loop
          nav: true, // Show navigation buttons
          dots: false, // Hide pagination dots
          margin: 10, // Space between items
          responsive: {
            // Responsive breakpoints
            0: { items: 1 },
            576: { items: 2 },
            768: { items: 3 },
            992: { items: 4 }
          }
        });
      
    const form = this.contactForm.nativeElement;

    // Attach event listener on form submission
    form.addEventListener('submit', event => {
      event.preventDefault(); // Prevent the form from submitting normally

      // Send the form data via Formspree
      const xhr = new XMLHttpRequest();
      xhr.open('POST', form.action);
      xhr.setRequestHeader('Accept', 'application/json');
      xhr.onreadystatechange = () => {
        if (xhr.readyState !== XMLHttpRequest.DONE) return;
        if (xhr.status === 200) {
          // Success message
          form.reset();
          alert('Thank you! Your message has been sent.');
        } else {
          // Error message
          alert('Oops! Something went wrong.');
        }
      };
      xhr.send(new FormData(form));
    });
  }

  openLightbox(image: any) {
    const modal = document.getElementById('lightbox-modal');
    const imageElement = document.getElementById('lightbox-image');
  
    // Get the clicked image source and caption
    const src = image.src;
    const caption = image.caption;
  
    // Set the source and caption for the enlarged image
    imageElement.setAttribute('src', src);
    imageElement.setAttribute('alt', caption);
  
    // Show the modal
    modal.classList.add('uk-open');
  }
}
